import * as React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { ExchangeType } from "@/routes";
import { Exchange } from "@/pages/ExchangePage/components/Exchange";
import { useProvider, useStore } from "@/shared/hooks/useStore";
import { ExchangeStore } from "@/pages/ExchangePage/stores/ExchangeStore";
import { ChooseCryptoExchange } from "@/pages/ExchangePage/components/ChooseCryptoExchange";
export var ExchangePage = observer(function () {
    var params = useParams();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var exchange = useStore(ExchangeStore);
    var ExchangeProvider = useProvider(ExchangeStore);
    if (!from && !to) {
        return React.createElement(ChooseCryptoExchange, null);
    }
    return (React.createElement(ExchangeProvider, { value: exchange }, params.type === ExchangeType.Buy ? (React.createElement(Exchange, { type: ExchangeType.Buy })) : (React.createElement(Exchange, { type: ExchangeType.Sell }))));
});
