import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var CardActionButton = function (_a) {
    var icon = _a.icon, label = _a.label;
    return (React.createElement(Box, { display: "flex", alignItems: "center", gap: "4px", flexDirection: "column", width: "100px" },
        React.createElement(Box, { width: 44, height: 44, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "16px", bgColor: "deep-blue-500" },
            React.createElement(BaseIcon, { icon: icon, size: 24, color: "black-50" })),
        React.createElement(Box, null,
            React.createElement(Typography, { color: "black-1000", type: "text16" }, label))));
};
