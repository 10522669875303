import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var CardDetailButton = function (_a) {
    var icon = _a.icon, title = _a.title, subtitle = _a.subtitle;
    return (React.createElement(Box, { display: "flex", alignItems: "center", padding: "12px 16px", borderRadius: "16px", marginBottom: "16px", sx: {
            cursor: "pointer",
            boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        } },
        React.createElement(Box, { width: 44, height: 44, display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "16px", bgColor: "deep-blue-500", marginRight: "16px" },
            React.createElement(BaseIcon, { icon: icon, size: 24, color: "black-50" })),
        React.createElement(Box, null,
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text16" }, title)),
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text12", color: "black-500" }, subtitle)))));
};
