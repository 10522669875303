import React from "react";
import { observer } from "mobx-react-lite";
import { action, runInAction } from "mobx";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@/shared/components";
import appleCard from "@/shared/img/apple-card.png";
import { PaymentCurrencyDropdown } from "@/shared/components/PaymentCurrencyDropdown";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardOrderStore } from "@/pages/CardOrderPage/stores/CardOrderStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { AddCurrencyBanner } from "@/shared/components/AddCurrencyBanner";
import { PromoCodeInput } from "@/shared/components/PromoCodeInput";
import { onChange } from "@/shared/utils/input";
import { Amount } from "@/shared/components/Amount";
import { useToggler } from "@/shared/hooks/useToggler";
import { SelectBlockchainModal } from "@/shared/components/SelectBlockchainModal";
import { generateQueryPath } from "@/shared/utils/path";
import { ROUTES } from "@/routes";
export var Payment = observer(function (_a) {
    var _b, _c, _d, _e, _f;
    var offer = _a.offer;
    var selectBlockchainPopup = useToggler();
    var navigate = useNavigate();
    var cardOrder = useStoreContext(CardOrderStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var accountList = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var currencies = currencyList.withWalletEnabled.filter(function (c) { return c.type === "CRYPTO"; });
    var account = accountList.checking;
    React.useEffect(function () {
        if (account) {
            balanceList.sync(account.account);
        }
    }, [accountList.list, account, balanceList]);
    var onCurrencyChange = function (c, enoughFunds, convertedAmount) {
        runInAction(function () {
            cardOrder.selectedCurrency = c;
            cardOrder.enoughFunds = enoughFunds;
            cardOrder.convertedAmount = convertedAmount;
        });
    };
    var applyPromoCode = action(function () {
        cardOrder.promoCodeApplied = true;
    });
    var clearPromoCode = action(function () {
        cardOrder.promoCodeApplied = false;
        cardOrder.promoCode = "";
    });
    var onBlockchainSelect = function (blockchain) {
        var _a, _b;
        runInAction(function () {
            cardOrder.selectedBlockchain = blockchain;
        });
        navigate(generateQueryPath(ROUTES.receiveCrypto, {}, {
            currency: (_a = cardOrder.selectedCurrency) === null || _a === void 0 ? void 0 : _a.slug,
            blockchain: (_b = cardOrder.selectedBlockchain) === null || _b === void 0 ? void 0 : _b.slug,
        }));
        selectBlockchainPopup.off();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { padding: "32px", borderRadius: "22px", bgColor: "general-white" },
            React.createElement(Box, { display: "flex", gap: "32px" },
                React.createElement("img", { alt: "apple-card", src: appleCard, width: "360px" }),
                React.createElement(Box, { width: "100%", display: "flex", flexDirection: "column", gap: "32px", marginTop: "72px" },
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
                        React.createElement(Typography, { type: "text32Bold" }, "Visa Platinum"),
                        React.createElement(Typography, { type: "text24Bold" }, offer === null || offer === void 0 ? void 0 :
                            offer.virtualPriceCurrency,
                            " ", offer === null || offer === void 0 ? void 0 :
                            offer.virtualPriceAmount)),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
                        currencies.length > 0 && (React.createElement(PaymentCurrencyDropdown, { totalAmount: ((_b = offer === null || offer === void 0 ? void 0 : offer.virtualPriceAmount) === null || _b === void 0 ? void 0 : _b.toString()) ||
                                "0", selectedCurrency: cardOrder.selectedCurrency ||
                                currencies[0], balances: balanceList.data, currencies: currencies, onCurrencyChange: onCurrencyChange })),
                        !cardOrder.enoughFunds &&
                            cardOrder.selectedCurrency && (React.createElement(AddCurrencyBanner, { amount: cardOrder.convertedAmount, description: cardOrder.selectedCurrency
                                .description, name: cardOrder.selectedCurrency.shortName })),
                        React.createElement(PromoCodeInput, { value: cardOrder.promoCode, onChange: onChange(action(function (val) {
                                if (!cardOrder.promoCodeApplied) {
                                    cardOrder.promoCode = val;
                                }
                            })), applied: cardOrder.promoCodeApplied, onApply: function () {
                                applyPromoCode();
                            }, onClear: function () {
                                clearPromoCode();
                            }, isValid: cardOrder.isPromoCodeValid }),
                        React.createElement(Box, null,
                            React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                                React.createElement(Typography, { type: "text16", color: "black-500" }, "Total"),
                                React.createElement(Typography, { type: "text16Bold" }, (_c = cardOrder.selectedCurrency) === null || _c === void 0 ? void 0 :
                                    _c.shortName,
                                    " ",
                                    React.createElement(Amount, { from: (_d = cardOrder.selectedCurrency) === null || _d === void 0 ? void 0 : _d.slug, to: (_e = cardOrder.selectedCurrency) === null || _e === void 0 ? void 0 : _e.slug, amount: cardOrder.convertedAmount })))))))),
        React.createElement(Box, { display: "flex", justifyContent: "center", marginBottom: "32px" }, cardOrder.enoughFunds ? (React.createElement(Button, { size: "l", width: "100%", sx: { maxWidth: "328px" }, onClick: function () {
                cardOrder.createCardRequest();
            } }, "Get card")) : (React.createElement(Button, { size: "l", onClick: function () {
                selectBlockchainPopup.on();
            }, width: "100%", sx: { maxWidth: "328px" } },
            "Add ", (_f = cardOrder.selectedCurrency) === null || _f === void 0 ? void 0 :
            _f.shortName,
            " and card"))),
        selectBlockchainPopup.enabled && (React.createElement(SelectBlockchainModal, { blockchainList: cardOrder.availableBlockchains, onClose: selectBlockchainPopup.off, onBlockchainSelect: onBlockchainSelect }))));
});
