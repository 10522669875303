import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
export var SelectBlockchainModal = function (_a) {
    var blockchainList = _a.blockchainList, onBlockchainSelect = _a.onBlockchainSelect, onClose = _a.onClose;
    return (React.createElement(Popup, { onClose: onClose, width: "480px" },
        React.createElement(Box, { padding: "16px 32px" },
            React.createElement(Box, { display: "flex", justifyContent: "flex-end" },
                React.createElement(BaseIcon, { icon: "Close", size: 24, onClick: onClose })),
            React.createElement(Box, { marginTop: "12px", display: "flex", alignItems: "center", flexDirection: "column", gap: "10px", paddingX: "32px" },
                React.createElement(Typography, { type: "text28Bold" }, "Select network"),
                React.createElement(Typography, { type: "text14", textAlign: "center" }, "Make sure you select the right network, or you funds might be lost")),
            React.createElement(Box, { marginTop: "32px" }, blockchainList === null || blockchainList === void 0 ? void 0 : blockchainList.map(function (blockchain) { return (React.createElement(Box, { key: blockchain.name, padding: "16px", height: "44px", display: "flex", justifyContent: "space-between", alignItems: "center", onClick: function () { return onBlockchainSelect(blockchain); }, borderRadius: "24px", sx: {
                    "&:hover": {
                        backgroundColor: "#F6F7F8",
                    },
                    cursor: "pointer",
                } },
                React.createElement(Typography, { type: "text16Bold" }, blockchain === null || blockchain === void 0 ? void 0 : blockchain.name),
                React.createElement(BaseIcon, { icon: "Navigation", size: 24, color: "black-500" }))); })))));
};
