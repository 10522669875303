import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { Amount } from "@/shared/components/Amount";
export var AddCurrencyBanner = function (_a) {
    var name = _a.name, amount = _a.amount, description = _a.description;
    return (React.createElement(Box, { display: "flex", gap: "12px", sx: {
            backgroundColor: "#F6F7F8",
        }, borderRadius: "16px", alignItems: "center", padding: "16px", boxSizing: "border-box" },
        React.createElement(BaseIcon, { icon: "Warning", size: 44 }),
        React.createElement(Typography, { color: "black-500", type: "text14" },
            "You need to add ",
            name,
            " ",
            React.createElement(Amount, { from: name === null || name === void 0 ? void 0 : name.toLowerCase(), to: name === null || name === void 0 ? void 0 : name.toLowerCase(), amount: amount }),
            " ",
            "to your ",
            description,
            " wallet to get subscribed")));
};
