var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { formattedTokenAmount } from "@broxus/js-utils";
import { action, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmPopup } from "@/features/transfer/components/ConfirmPopup";
import { FailPopup } from "@/features/transfer/components/FailPopup";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { Header } from "@/pages/TransferPage/components/Header";
import { TransferCryptoFormStore } from "@/pages/TransferPage/stores/TransferCryptoFormStore";
import { ROUTES, TransferType } from "@/routes";
import { Box, Button, FormControlInput, Typography } from "@/shared/components";
import { AccountSelect } from "@/shared/components/AccountSelect";
import { ExchangeField } from "@/shared/components/ExchangeField";
import { ExchangeInput } from "@/shared/components/ExchangeInput";
import { FormAlert } from "@/shared/components/FormAlert";
import { Info } from "@/shared/components/Info";
import { OperationLayout } from "@/shared/components/OperationLayout";
import { SectionAmount } from "@/shared/components/SectionAmount";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { TokenPopup } from "@/shared/components/TokenPopup";
import { useMedia } from "@/shared/hooks/useMedia";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { RecipientListStore } from "@/shared/stores/RecipientListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { sliceAddress } from "@/shared/utils/slice-address";
import { BlockchainPopup } from "@/shared/components/BlockchainPopup";
import { DropdownInput } from "@/shared/components/DropdownInput";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var Crypto = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var media = useMedia();
    var tokenPopup = useToggler();
    var blockchainPopup = useToggler();
    var navigate = useNavigate();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var accountList = useStoreContext(AccountListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var recipientList = useStoreContext(RecipientListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var FormProvider = useProvider(TransferCryptoFormStore);
    var form = useStore(TransferCryptoFormStore, balanceList, accountList, recipientList);
    var error = form.enough === false ? "Enter a smaller amount" : undefined;
    React.useEffect(function () {
        runInAction(function () {
            form.from = from !== null && from !== void 0 ? from : undefined;
            form.to = to !== null && to !== void 0 ? to : undefined;
        });
    }, [from, form, to]);
    var info = [
        {
            label: "Amount",
            value: ((_a = form.feeData.value) === null || _a === void 0 ? void 0 : _a.data.amount) && form.from
                ? "".concat(formattedTokenAmount(form.feeData.value.data.amount), " ").concat(form.from.toUpperCase())
                : "–",
        },
        {
            label: "Transaction fee",
            value: form.fee ? formattedTokenAmount(form.fee) : "–",
        },
        {
            label: "Processing time",
            value: "Instantly",
        },
    ];
    return (React.createElement(FormProvider, { value: form },
        tokenPopup.enabled && (React.createElement(TokenPopup, { onClose: tokenPopup.off, onSelect: function (id) {
                tokenPopup.off();
                navigate(generateQueryPath(ROUTES.transfer, {
                    type: TransferType.Crypto,
                }, {
                    from: id,
                }), {
                    replace: true,
                });
            } })),
        blockchainPopup.enabled && (React.createElement(BlockchainPopup, { currencySlug: from !== null && from !== void 0 ? from : undefined, onClose: blockchainPopup.off, onSelect: function (blockchain) {
                blockchainPopup.off();
                form.fromBlockchain = blockchain;
            } })),
        React.createElement(OperationLayout, { main: React.createElement(Box, null,
                React.createElement(Header, { active: TransferType.Crypto }),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(ExchangeField, { error: error, input: React.createElement(ExchangeInput, { disabled: form.loading, invalid: !!error, postFix: form.from
                                ? form.from.toUpperCase()
                                : undefined, value: form.amount, onChange: action(function (value) {
                                form.amount = value;
                            }) }), label: "You send", select: React.createElement(AccountSelect, { disabled: form.loading, icon: form.fromAcc ? (React.createElement(TokenIcon, { symbol: (_b = form.from) !== null && _b !== void 0 ? _b : undefined, size: 24 })) : undefined, name: form.from
                                ? form.from.toUpperCase()
                                : undefined, onClick: tokenPopup.on, descLoading: !form.balance, desc: form.balance
                                ? "".concat(formattedTokenAmount(form.balance.balance), " ").concat(form.balance.currency.toUpperCase())
                                : undefined }) }),
                    React.createElement(DropdownInput, { loading: form.loading, onClick: blockchainPopup.on, disabled: !from }, form.fromBlockchain ? (React.createElement(Typography, { type: "text16" }, (_c = currencyList.blockchainList.find(function (item) {
                        return item.slug ===
                            form.fromBlockchain;
                    })) === null || _c === void 0 ? void 0 : _c.name)) : (React.createElement(Typography, { type: "text16" }, "Select Network"))),
                    React.createElement(FormControlInput, { placeholder: "Address", value: form.toAddress, disabled: !from, onChange: action(function (e) {
                            form.toAddress = e.currentTarget.value;
                        }) }),
                    form.errors.at(0) && (React.createElement(FormAlert, { text: form.errors.at(0).description })))), card: media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "50px" },
                React.createElement(Info, { data: info }),
                React.createElement(Box, { height: "1px", bgColor: "black-1000", width: "100%" }),
                React.createElement(SectionAmount, { amount: form.total, currency: form.from }),
                React.createElement(Button, { width: "100%", disabled: !form.ready, onClick: form.showConfirmation, loading: form.feeData.loading || form.loading }, "Send"))), side: !media.desktop && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "30px", width: "100%" },
                React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                React.createElement(Info, { dark: false, data: __spreadArray(__spreadArray([], info, true), [
                        {
                            label: "Total + Fees",
                            value: form.fromAcc
                                ? "".concat((_d = form.total) !== null && _d !== void 0 ? _d : "0.00", " ").concat(form.from)
                                : "\u200b",
                        },
                    ], false) }),
                React.createElement(Button, { width: "100%", disabled: !form.ready, onClick: form.showConfirmation, loading: form.feeData.loading || form.loading }, "Send"))) }),
        form.confirmation && (React.createElement(ConfirmPopup, { info: info, isLoading: form.loading, onClose: form.hideConfirmation, onSubmit: form.submit, amount: form.amount, currency: form.from, to: ((_e = form.recipient) === null || _e === void 0 ? void 0 : _e.blockchainAddress)
                ? sliceAddress(form.recipient.blockchainAddress)
                : undefined })),
        form.result && (React.createElement(SuccessPopup, { minus: true, title: "You've successfully sent the crypto", address: ((_f = form.recipient) === null || _f === void 0 ? void 0 : _f.blockchainAddress)
                ? sliceAddress(form.recipient.blockchainAddress)
                : undefined, amount: form.total, createdAt: (_g = form.result) === null || _g === void 0 ? void 0 : _g.createdAt, currency: (_h = form.result) === null || _h === void 0 ? void 0 : _h.currency, onClose: form.reset })),
        !!form.submitErrors.length && (React.createElement(FailPopup, { message: (_j = form.submitErrors.at(0)) === null || _j === void 0 ? void 0 : _j.description, onClose: form.hideErrors, onSubmit: form.showConfirmation }))));
});
