import React from "react";
import { observer } from "mobx-react-lite";
import { Box } from "@/shared/components";
import { PickCard } from "@/pages/CardOrderPage/components/PickCard";
import { CardOrderStore } from "@/pages/CardOrderPage/stores/CardOrderStore";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardOffersStore } from "@/shared/stores/CarfOffersStore";
import { Stepper } from "@/shared/components/Stepper";
import { PageHeader } from "@/shared/components/PageHeader";
import { Billing } from "@/pages/CardOrderPage/components/Billing";
import { CardHolderName } from "@/pages/CardOrderPage/components/CardHolderName";
import { Payment } from "@/pages/CardOrderPage/components/Payment";
export var CardOrderPage = observer(function () {
    var cardOrder = useStoreContext(CardOrderStore);
    var cardOffers = useStoreContext(CardOffersStore);
    var renderStep = function (step) {
        switch (step) {
            case 1:
                return React.createElement(PickCard, { offer: cardOffers.list[0] });
            case 3:
                return React.createElement(Billing, null);
            case 4:
                return React.createElement(CardHolderName, null);
            case 5:
                return React.createElement(Payment, { offer: cardOffers.list[0] });
            default:
                return null;
        }
    };
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
        React.createElement(PageHeader, { title: "Add card" }),
        React.createElement(Stepper, { steps: cardOrder.availableSteps, activeStep: cardOrder.currentStep }),
        cardOffers.list.length > 0 && renderStep(cardOrder.currentStep)));
});
