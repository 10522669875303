var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { styled } from "@mui/material";
import * as React from "react";
import { BaseIcon } from "@/shared/components/BaseIcon";
import { Box } from "@/shared/components/Box";
import { Loader } from "@/shared/components/Loader";
export var DropdownInput = function (_a) {
    var onClick = _a.onClick, disabled = _a.disabled, loading = _a.loading, children = _a.children;
    return (React.createElement(Wrapper, { disabled: disabled, onClick: onClick },
        React.createElement(Box, { display: "flex", alignItems: "center", gap: "10px" }, children),
        loading ? (React.createElement(Loader, { size: 18, color: "black-200", opacity: 1 })) : (React.createElement(BaseIcon, { icon: "DropDown", size: 24, p: "0", color: "black-500" }))));
};
var Wrapper = styled("button")(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: 100%;\n    border: 1px solid ", ";\n    border-radius: 16px;\n    height: 60px;\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 20px;\n"], ["\n    width: 100%;\n    border: 1px solid ", ";\n    border-radius: 16px;\n    height: 60px;\n    display: flex;\n    gap: 10px;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 20px;\n"])), function (props) { return props.theme.colors["black-200"]; });
var templateObject_1;
