import React from "react";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { Amount } from "@/shared/components/Amount";
import { useToggler } from "@/shared/hooks/useToggler";
import { Input } from "@/shared/components/Input";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var PaymentCurrencyDropdown = observer(function (_a) {
    var totalAmount = _a.totalAmount, onCurrencyChange = _a.onCurrencyChange, currencies = _a.currencies, selectedCurrency = _a.selectedCurrency, balances = _a.balances;
    var currencyList = useStoreContext(CurrencyListStore);
    var allCurrencies = currencyList.list;
    var currencyDropDrown = useToggler();
    var _b = React.useState(""), searchTerm = _b[0], setSearchTerm = _b[1];
    var _c = React.useState(false), enoughFunds = _c[0], setEnoughFunds = _c[1];
    var _d = React.useState("0"), convertedAmount = _d[0], setConvertedAmount = _d[1];
    var convertAmount = function (fromRate, toRate, amount) {
        if (fromRate && toRate) {
            var value = BigNumber(fromRate)
                .multipliedBy(amount)
                .dividedBy(toRate)
                .toFixed(10);
            return value;
        }
        return null;
    };
    React.useEffect(function () {
        var _a, _b, _c;
        var selectedBalance = ((_b = balances[((_a = selectedCurrency.slug) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || ""]) === null || _b === void 0 ? void 0 : _b.balance) || "0";
        var fromRate = (_c = allCurrencies.find(function (c) { var _a; return ((_a = c.slug) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "usd"; })) === null || _c === void 0 ? void 0 : _c.eurPrice;
        var toRate = selectedCurrency.eurPrice;
        if (fromRate && toRate) {
            var converted = convertAmount(fromRate, toRate, totalAmount);
            setConvertedAmount(converted || "0");
            var hasEnoughFunds = BigNumber(selectedBalance).isGreaterThanOrEqualTo(converted || 0);
            setEnoughFunds(hasEnoughFunds);
            onCurrencyChange(selectedCurrency, hasEnoughFunds, converted || "0");
        }
    }, [
        balances,
        allCurrencies,
        selectedCurrency,
        enoughFunds,
        totalAmount,
    ]);
    var filteredCurrencies = currencies.filter(function (currency) {
        var _a;
        return (_a = currency === null || currency === void 0 ? void 0 : currency.shortName) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchTerm.toLowerCase());
    });
    var getBalance = function () {
        var _a;
        return selectedCurrency.slug
            ? (_a = balances[selectedCurrency.slug]) === null || _a === void 0 ? void 0 : _a.balance
            : "0";
    };
    React.useEffect(function () {
        onCurrencyChange(selectedCurrency, enoughFunds, convertedAmount);
    }, [
        convertedAmount,
        enoughFunds,
        onCurrencyChange,
        selectedCurrency,
    ]);
    return (React.createElement(Box, { sx: {
            border: enoughFunds
                ? "1px solid #DDDFE4"
                : "1px solid #FF6464",
        }, borderRadius: "16px", padding: "12px 16px", display: "flex", justifyContent: "space-between", height: "66px", boxSizing: "border-box", alignItems: "center", position: "relative" },
        React.createElement(Box, null,
            React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center", sx: { cursor: "pointer" }, onClick: currencyDropDrown.enabled
                    ? currencyDropDrown.off
                    : currencyDropDrown.on },
                React.createElement(Box, { display: "flex", gap: "4px", alignItems: "center" },
                    React.createElement(TokenIcon, { symbol: selectedCurrency.slug, size: 24 }),
                    React.createElement(Typography, { type: "text16" }, selectedCurrency.shortName),
                    React.createElement(Box, { bgColor: "black-100", borderRadius: "100px", padding: "2px 8px" },
                        React.createElement(Typography, { type: "text14" }, selectedCurrency.description))),
                React.createElement(BaseIcon, { icon: "DropDown" })),
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text12", color: enoughFunds ? "black-500" : "general-red" },
                    "Balance: ",
                    getBalance()))),
        React.createElement(Typography, { type: "text18", color: enoughFunds ? "black-500" : "general-red" },
            "-",
            React.createElement(Amount, { from: "usd", to: selectedCurrency.slug, amount: totalAmount, withSymbol: false })),
        !enoughFunds && (React.createElement(Box, { position: "absolute", top: "calc(100% + 4px)", right: "15px" },
            React.createElement(Typography, { color: "general-red", type: "text12" }, "Not enough funds"))),
        currencyDropDrown.enabled && (React.createElement(Box, { position: "absolute", top: "calc(100% + 14px)", left: "0px", width: "100%", maxHeight: "528px", overflow: "scroll", padding: "12px", zIndex: 1000, bgColor: "general-white", borderRadius: "16px", boxSizing: "border-box", sx: {
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.25)",
            } },
            React.createElement(Input, { InputProps: {
                    startAdornment: (React.createElement(BaseIcon, { icon: "Search", color: "black-400" })),
                }, placeholder: "Search for crypto", value: searchTerm, onChange: function (event) {
                    setSearchTerm(event.target.value);
                } }),
            React.createElement(Box, { marginTop: "6px" }, filteredCurrencies.map(function (c) {
                var _a, _b;
                return (React.createElement(Box, { display: "flex", alignItems: "center", borderRadius: "12px", justifyContent: "space-between", sx: {
                        "&:hover": {
                            backgroundColor: "#F6F7F8",
                        },
                    }, padding: "9px 20px", onClick: function () {
                        onCurrencyChange(c, enoughFunds, convertedAmount);
                        currencyDropDrown.off();
                    } },
                    React.createElement(Box, { display: "flex", gap: "10px" },
                        React.createElement(TokenIcon, { symbol: c.slug, size: 36 }),
                        React.createElement(Box, { display: "flex", alignItems: "center", gap: "6px" },
                            React.createElement(Typography, { type: "text16" }, c.shortName))),
                    React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-end" },
                        React.createElement(Typography, { type: "text14" },
                            c.shortName,
                            React.createElement(Amount, { from: c.slug, to: c.slug, amount: c.slug
                                    ? (_a = balances[c.slug]) === null || _a === void 0 ? void 0 : _a.balance
                                    : "0" })),
                        React.createElement(Typography, { type: "text12", color: "black-500" },
                            React.createElement(Amount, { from: c.slug, amount: c.slug
                                    ? (_b = balances[c.slug]) === null || _b === void 0 ? void 0 : _b.balance
                                    : "0" })))));
            }))))));
});
