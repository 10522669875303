import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { CardActionButton } from "@/pages/CardPage/components/CardDetailsModal/CardActionButton";
import { CardDetailButton } from "@/pages/CardPage/components/CardDetailsModal/CardDetailButton";
import { CardFront } from "@/pages/CardPage/components/Card/CardFront";
import { CardBack } from "@/pages/CardPage/components/Card/CardBack";
export var CardDetailsModal = function (_a) {
    var card = _a.card, onClose = _a.onClose;
    var _b = React.useState(true), isFront = _b[0], setIsFront = _b[1];
    var toggleSide = function () {
        setIsFront(!isFront);
    };
    var getCurrentMonthName = function () {
        var date = new Date();
        var monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        return monthNames[date.getMonth()];
    };
    return (React.createElement(Box, { width: "370px", position: "fixed", top: "32px", right: "32px", bgColor: "general-white", borderRadius: "22px", height: "calc(100vh - 64px)", zIndex: 99, sx: {
            boxShadow: "0px 4px 30px rgba(0, 0, 0, 0.25)",
        } },
        React.createElement(Box, { padding: "16px 32px 32px 32px" },
            React.createElement(Box, { display: "flex", marginBottom: "24px", gap: "4px" },
                React.createElement(BaseIcon, { icon: "Close", size: 24, onClick: onClose }),
                React.createElement(Typography, null, card.cardName)),
            isFront ? (React.createElement(CardFront, { card: card, toggleSide: toggleSide })) : (React.createElement(CardBack, { card: card, toggleSide: toggleSide })),
            React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "10px" },
                React.createElement(Box, { display: "flex", gap: "4px", alignItems: "center" },
                    React.createElement(BaseIcon, { icon: "Cloud", color: "black-500" }),
                    React.createElement(Typography, { type: "text16", color: "black-500" }, "Virtual"))),
            React.createElement(Box, { marginTop: "24px", display: "flex", justifyContent: "space-around" },
                React.createElement(CardActionButton, { icon: "PlusSmall", label: "Add money" }),
                React.createElement(CardActionButton, { icon: "Freeze", label: "Freeze" }),
                React.createElement(CardActionButton, { icon: "Block", label: "Block" })),
            React.createElement(Box, { marginTop: "24px" },
                React.createElement(CardDetailButton, { icon: "Clock", title: "Card transactions", subtitle: "Nothing spent in ".concat(getCurrentMonthName()) }),
                React.createElement(CardDetailButton, { icon: "Limits", title: "Card limit", subtitle: "Limit is not set" }),
                React.createElement(CardDetailButton, { icon: "Settings", title: "Settings" })))));
};
