import React from "react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { ChooseCryptoList } from "@/shared/components/ChooseCryptoList";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SendCryptoStep, SendCryptoStore, } from "@/pages/SendCryptoPage/stores/SendCryptoStore";
import { Box } from "@/shared/components";
import { PageHeader } from "@/shared/components/PageHeader";
import { SelectBlockchainModal } from "@/shared/components/SelectBlockchainModal";
import { useToggler } from "@/shared/hooks/useToggler";
export var ChooseCryptoSend = observer(function () {
    var selectBlockchainModal = useToggler();
    var sendCrypto = useStoreContext(SendCryptoStore);
    var onCurrencySelect = function (currency) {
        var tokens = currency.tokens;
        if (tokens) {
            runInAction(function () {
                sendCrypto.fromCurrency = currency;
            });
            if (tokens.length === 1) {
                runInAction(function () {
                    sendCrypto.fromBlockchain = tokens[0].blockchain;
                    sendCrypto.step = SendCryptoStep.Form;
                });
            }
            if (tokens.length > 1) {
                selectBlockchainModal.on();
            }
        }
    };
    var onBlockchainSelect = function (blockchain) {
        runInAction(function () {
            sendCrypto.fromBlockchain = blockchain;
            sendCrypto.step = SendCryptoStep.Form;
        });
        selectBlockchainModal.off();
    };
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
        React.createElement(PageHeader, { title: "Choose Crypto" }),
        React.createElement(ChooseCryptoList, { onCurrencySelect: onCurrencySelect }),
        sendCrypto.fromCurrency && selectBlockchainModal.enabled && (React.createElement(SelectBlockchainModal, { blockchainList: sendCrypto.availableBlockchains, onClose: selectBlockchainModal.off, onBlockchainSelect: onBlockchainSelect }))));
});
