import React from "react";
import { Box } from "@/shared/components";
import cardFront from "@/shared/img/cards/card-front.png";
import cardBack from "@/shared/img/cards/card-back.png";
export var PaymentCard = function () {
    return (React.createElement(Box, { display: "flex", gap: "30px", width: "100%" },
        React.createElement(Box, null,
            React.createElement("img", { src: cardFront, alt: "card-front", style: { width: "100%", objectFit: "contain" } })),
        React.createElement(Box, null,
            React.createElement("img", { src: cardBack, alt: "card-back", style: { width: "100%", objectFit: "contain" } }))));
};
