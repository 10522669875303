import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var CardFront = function (_a) {
    var card = _a.card, toggleSide = _a.toggleSide;
    return (React.createElement(Box, { width: "305px", height: "192px", bgColor: "black-1200", borderRadius: "16px", display: "flex", justifyContent: "space-between", flexDirection: "column", padding: "24px", boxSizing: "border-box" },
        React.createElement(Box, { width: "100%", display: "flex", justifyContent: "space-between", alignItems: "flex-start" },
            React.createElement(BaseIcon, { icon: "CardFlip", size: 24, onClick: toggleSide, style: {
                    cursor: "pointer",
                } }),
            React.createElement(BaseIcon, { icon: "Visa", width: 64, height: 32 })),
        React.createElement(Box, null,
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text20", color: "general-white" },
                    "\u2022\u2022 ", card === null || card === void 0 ? void 0 :
                    card.last4)),
            React.createElement(Box, { marginTop: "24px" },
                React.createElement(Typography, { type: "text14", color: "general-white" }, card === null || card === void 0 ? void 0 : card.cardName)))));
};
