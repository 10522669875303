import React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var PickCardAdvantage = function (_a) {
    var icon = _a.icon, text = _a.text, title = _a.title;
    return (React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
        React.createElement(BaseIcon, { icon: icon, size: 44 }),
        React.createElement(Box, null,
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text18", fontWeight: 700 }, title)),
            React.createElement(Box, null,
                React.createElement(Typography, { type: "text14", marginTop: "4px", color: "black-500" }, text)))));
};
