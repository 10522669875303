import React from "react";
import { InputAdornment } from "@mui/material";
import { observer } from "mobx-react-lite";
import { BaseIcon, Box, FormControlInput, Typography, } from "@/shared/components";
export var PromoCodeInput = observer(function (_a) {
    var value = _a.value, onChange = _a.onChange, applied = _a.applied, onApply = _a.onApply, onClear = _a.onClear, isValid = _a.isValid;
    return (React.createElement(FormControlInput, { fullWidth: true, placeholder: "Promo code", label: "Promo code", value: value, onChange: onChange, error: value &&
            applied &&
            !isValid &&
            "We need a correct promo code, please", InputProps: {
            endAdornment: (React.createElement(InputAdornment, { position: "end" }, value ? (applied && isValid ? (React.createElement(BaseIcon, { icon: "Success" })) : (React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center" },
                value && (React.createElement(BaseIcon, { icon: "Clear", onClick: function () {
                        return onClear && onClear();
                    } })),
                React.createElement(Box, { display: "flex", justifyContent: "center", alignItems: "center", sx: {
                        width: "56px",
                        height: "36px",
                        backgroundColor: "#F6F7F8",
                        borderRadius: "12px",
                        cursor: "pointer",
                    }, onClick: onApply },
                    React.createElement(Typography, { type: "text12", style: {
                            color: "#0019FF",
                        } }, "Apply"))))) : (React.createElement(Typography, { type: "text12", color: "black-500" }, "Optional")))),
        } }));
});
