import React from "react";
import { observer } from "mobx-react-lite";
import { action } from "mobx";
import { Box, Button, Typography } from "@/shared/components";
import { PaymentCard } from "@/pages/CardPage/components/OrderConfirmation/PaymentCard";
import { Input } from "@/shared/components/Input";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
import { CardOrderStore } from "@/pages/CardOrderPage/stores/CardOrderStore";
export var CardHolderName = observer(function () {
    var cardOrder = useStoreContext(CardOrderStore);
    var handleNextStepClick = function () {
        cardOrder.nextStep();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { padding: "32px", borderRadius: "22px", bgColor: "general-white" },
            React.createElement(Box, { display: "flex", gap: "62px" },
                React.createElement(Box, { flexBasis: "60%", maxWidth: "60%" },
                    React.createElement(PaymentCard, null)),
                React.createElement(Box, { flexBasis: "40%", maxWidth: "40%" },
                    React.createElement(Input, { placeholder: "Cardholder name", label: "Cardholder name", value: cardOrder.cardholderName, onChange: onChange(action(function (value) {
                            cardOrder.cardholderName = value;
                        })), fullWidth: true }),
                    React.createElement(Box, { display: "flex", flexDirection: "row-reverse", paddingX: "16px", marginTop: "2px" },
                        React.createElement(Typography, { color: "black-500" },
                            cardOrder.cardholderName.length,
                            "/27"))))),
        React.createElement(Box, { display: "flex", justifyContent: "center" },
            React.createElement(Button, { onClick: handleNextStepClick, size: "l", style: {
                    maxWidth: "328px",
                    width: "100%",
                } }, "Next"))));
});
