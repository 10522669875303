import React from "react";
import { runInAction } from "mobx";
import { useStoreContext } from "@/shared/hooks/useStore";
import { ReceiveCryptoStep, ReceiveCryptoStore, } from "@/pages/ReceiveCryptoPage/stores/ReceiveCryptoStore";
import { useToggler } from "@/shared/hooks/useToggler";
import { Box } from "@/shared/components";
import { PageHeader } from "@/shared/components/PageHeader";
import { ChooseCryptoList } from "@/shared/components/ChooseCryptoList";
import { SelectBlockchainModal } from "@/shared/components/SelectBlockchainModal";
export var ChooseCryptoReceive = function () {
    var selectBlockchainModal = useToggler();
    var receiveCrypto = useStoreContext(ReceiveCryptoStore);
    var onCurrencySelect = function (currency) {
        var tokens = currency.tokens;
        if (tokens) {
            runInAction(function () {
                receiveCrypto.currency = currency;
            });
            if (tokens.length === 1) {
                runInAction(function () {
                    receiveCrypto.blockchain = tokens[0].blockchain;
                    receiveCrypto.step = ReceiveCryptoStep.GetAddress;
                });
            }
            if (tokens.length > 1) {
                selectBlockchainModal.on();
            }
        }
    };
    var onBlockchainSelect = function (blockchain) {
        runInAction(function () {
            receiveCrypto.blockchain = blockchain;
            receiveCrypto.step = ReceiveCryptoStep.GetAddress;
        });
        selectBlockchainModal.off();
    };
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
        React.createElement(PageHeader, { title: "Choose Crypto" }),
        React.createElement(ChooseCryptoList, { onCurrencySelect: onCurrencySelect }),
        receiveCrypto.currency && selectBlockchainModal.enabled && (React.createElement(SelectBlockchainModal, { blockchainList: receiveCrypto.availableBlockchains, onClose: selectBlockchainModal.off, onBlockchainSelect: onBlockchainSelect }))));
};
