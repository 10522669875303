import React from "react";
import { Box, Typography } from "@/shared/components";
export var Stepper = function (_a) {
    var steps = _a.steps, activeStep = _a.activeStep;
    return (React.createElement(Box, { display: "flex", gap: "8px" }, steps.map(function (step, index) { return (React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center" },
        React.createElement(Box, { borderRadius: "50%", display: "flex", alignItems: "center", justifyContent: "center", bgColor: activeStep >= step.step ? "black-1200" : "black-100", width: "32px", height: "32px" },
            React.createElement(Typography, { color: activeStep >= step.step
                    ? "general-white"
                    : "black-500", type: "text16" }, index + 1)),
        React.createElement(Typography, { type: "text16", color: activeStep >= step.step ? "black-1200" : "black-500" }, step.label),
        index !== steps.length - 1 && (React.createElement(Box, { width: "20px", height: "1px", bgColor: "black-300" })))); })));
};
