import React, { useState } from "react";
import { Box, Button, Typography } from "@/shared/components";
import { BaseIcon } from "@/shared/components/BaseIcon";
export var CopyAddressButton = function (_a) {
    var address = _a.address;
    var _b = useState(false), isCopied = _b[0], setIsCopied = _b[1];
    var copyToClipboard = function () {
        navigator.clipboard.writeText(address);
        setIsCopied(true);
        setTimeout(function () {
            setIsCopied(false);
        }, 1500);
    };
    var getShortenedAddress = function (addr) {
        var firstPart = addr.slice(0, 12); // Первые 12 символов
        var lastPart = addr.slice(-4); // Последние 4 символа
        return "".concat(firstPart, "....").concat(lastPart); // Формируем сокращенный адрес
    };
    return (React.createElement(Button, { onClick: copyToClipboard, variant: isCopied ? "Success" : "LightBlue", sx: {
            display: "flex",
            alignItems: "center",
            gap: "8px",
            padding: "12px 16px",
            borderRadius: "12px",
            cursor: "pointer",
            width: "270px",
        }, size: "l" }, isCopied ? (React.createElement(Box, { display: "flex", gap: "4px" },
        React.createElement(Typography, { color: "general-green" }, "Copied"),
        React.createElement(BaseIcon, { icon: "Success" }))) : (React.createElement(Box, { display: "flex", gap: "4px" },
        React.createElement(Typography, { color: "deep-blue-500" }, getShortenedAddress(address)),
        React.createElement(BaseIcon, { icon: "Copy" })))));
};
