import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import { observer } from "mobx-react-lite";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { UserCurrencyStore } from "@/shared/stores/UserCurrencyStore";
export var Amount = observer(function (_a) {
    var from = _a.from, to = _a.to, amount = _a.amount, _b = _a.withSymbol, withSymbol = _b === void 0 ? true : _b;
    var rates = useStoreContext(CurrencyListStore).rates;
    var userCurrency = useStoreContext(UserCurrencyStore);
    if (from && amount) {
        var toCurrency = (to ||
            userCurrency.selected ||
            "usd").toLowerCase();
        var fromRate = rates[from.toLowerCase()];
        var toRate = rates[toCurrency];
        var currencySymbol = toCurrency === "usd" ? "$" : toCurrency === "eur" ? "€" : "";
        if ((fromRate === null || fromRate === void 0 ? void 0 : fromRate.eurPrice) && (toRate === null || toRate === void 0 ? void 0 : toRate.eurPrice)) {
            var value = BigNumber(fromRate.eurPrice)
                .multipliedBy(amount)
                .dividedBy(toRate.eurPrice)
                .toFixed();
            return "".concat(withSymbol ? currencySymbol : "").concat(formattedTokenAmount(value));
        }
    }
    return "\u200b";
});
