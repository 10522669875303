import React from "react";
import { observer } from "mobx-react-lite";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { SendCryptoStep, SendCryptoStore, } from "@/pages/SendCryptoPage/stores/SendCryptoStore";
import { ChooseCryptoSend } from "@/pages/SendCryptoPage/components/ChooseCryptoSend";
import { SendCryptoForm } from "@/pages/SendCryptoPage/components/SendCryptoForm";
import { AccountListStore } from "@/shared/stores/AccountListStore";
export var SendCryptoPage = observer(function () {
    var accounts = useStoreContext(AccountListStore);
    var sendCrypto = useStore(SendCryptoStore, accounts);
    var SendCryptoProvider = useProvider(SendCryptoStore);
    return (React.createElement(SendCryptoProvider, { value: sendCrypto }, sendCrypto.step === SendCryptoStep.ChooseCrypto ? (React.createElement(ChooseCryptoSend, null)) : (React.createElement(SendCryptoForm, null))));
});
