export var ROUTES = {
    root: "/",
    signin: "/signin",
    passwordReset: "/reset",
    deposit: "/deposit/:type?/:id?/:currency?/:blockchain?/",
    kyc: "/kyc",
    transfer: "/transfer/:type",
    bankAccountsList: "/account",
    bankAccountsItem: "/account/:id",
    subscription: "/subscription",
    exchange: "/exchange/:type",
    walletList: "/wallet",
    walletItem: "/wallet/:currency",
    fiatList: "/fiat",
    fiatItem: "/fiat/:currency",
    card: "/card/",
    orderCard: "/card/order",
    sendCrypto: "/crypto/send/",
    receiveCrypto: "/crypto/receive",
    transactions: "/transactions",
};
export var DepositType;
(function (DepositType) {
    DepositType["Crypto"] = "crypto";
    DepositType["Fiat"] = "fiat";
})(DepositType || (DepositType = {}));
export var TransferType;
(function (TransferType) {
    TransferType["Account"] = "account";
    TransferType["Recipient"] = "recipient";
    TransferType["Crypto"] = "crypto";
})(TransferType || (TransferType = {}));
export var ExchangeType;
(function (ExchangeType) {
    ExchangeType["Crypto"] = "crypto";
    ExchangeType["Fiat"] = "fiat";
    ExchangeType["Buy"] = "buy";
    ExchangeType["Sell"] = "sell";
})(ExchangeType || (ExchangeType = {}));
export var PaymentType;
(function (PaymentType) {
    PaymentType["Crypto"] = "crypto";
    PaymentType["Card"] = "card";
})(PaymentType || (PaymentType = {}));
