var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { styled } from "@mui/material/styles";
import { compose, flexbox, grid, spacing, } from "@mui/system";
import classNames from "classnames";
import React, { memo } from "react";
import { BaseIcon } from "../BaseIcon";
import { Loader } from "../Loader";
import { Typography } from "../Typography";
import { padding, paddingIcon, paddingWithIcon, sizeIcon, StyledAlert, StyledButton, } from "./styled";
var textColor = {
    Default: "general-white",
    Primary: "general-white",
    White: "black-1200",
    Gray: "black-1200",
    Bordered: "black-1200",
    BlueBordered: "deep-blue-500",
    LightBlue: "deep-blue-500",
    Black: "general-white",
    Transparent: "deep-blue-500",
};
var BaseButton = React.forwardRef(function (_a, ref) {
    var children = _a.children, icon = _a.icon, _b = _a.variant, variant = _b === void 0 ? "Default" : _b, _c = _a.size, size = _c === void 0 ? "m" : _c, className = _a.className, _d = _a.width, width = _d === void 0 ? "auto" : _d, minWidth = _a.minWidth, iconSize = _a.iconSize, active = _a.active, IconProps = _a.IconProps, _e = _a.iconPosition, iconPosition = _e === void 0 ? "left" : _e, flexDirection = _a.flexDirection, onClick = _a.onClick, error = _a.error, replace = _a.replace, loading = _a.loading, props = __rest(_a, ["children", "icon", "variant", "size", "className", "width", "minWidth", "iconSize", "active", "IconProps", "iconPosition", "flexDirection", "onClick", "error", "replace", "loading"]);
    var iconE = icon ? (React.createElement(BaseIcon, __assign({ icon: icon, color: props.disabled ? "black-500" : textColor[variant], size: iconSize || sizeIcon[size] }, IconProps))) : undefined;
    return (React.createElement(StyledButton, __assign({ replace: props.LinkComponent ? replace : undefined, ref: ref, className: classNames(className, "Button-".concat(variant), { ButtonIcon: icon && !children }, { ButtonActive: active }, { "ButtonDisabled Mui-disabled": props.disabled }), "$width": width, "$minWidth": minWidth, "$size": size, "$padding": icon && children
            ? paddingWithIcon[size]
            : icon
                ? paddingIcon[size]
                : padding[size], onClick: props.disabled ? undefined : onClick }, props),
        iconPosition === "left" ? iconE : null,
        children && (React.createElement(Typography, { type: (function () {
                switch (size) {
                    case "l":
                        return "text16";
                    case "m":
                        return "text14";
                    case "s":
                        return "text14";
                    case "xxs":
                        return "text12";
                    default:
                        return "text14";
                }
            })(), fontWeight: 400, color: props.disabled ? "black-500" : textColor[variant] }, children)),
        iconPosition === "right" ? iconE : null,
        loading && React.createElement(Loader, { size: 20 }),
        error && React.createElement(StyledAlert, { text: error })));
});
export var Button = memo(styled(BaseButton, {
    shouldForwardProp: function (prop) {
        return !compose(spacing, grid, flexbox).filterProps.includes(prop);
    },
})(compose(spacing, grid, flexbox)));
