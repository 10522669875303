import React from "react";
import { Input } from "@/shared/components/Input";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var WalletAddressInput = function (_a) {
    var value = _a.value, onChange = _a.onChange;
    return (React.createElement(Input, { placeholder: "Wallet address", value: value, onChange: function (event) {
            onChange(event.target.value);
        }, InputProps: {
            endAdornment: (React.createElement(Box, { padding: "8px 16px", display: "flex", gap: "4px", alignItems: "center", bgColor: "black-50", borderRadius: "10px" },
                React.createElement(Typography, { type: "text14" }, "Paste"),
                React.createElement(BaseIcon, { icon: "Copy", size: 24 }))),
        } }));
};
