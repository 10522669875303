var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from "mobx";
import { RecurrentInvoiceStatus, } from "@/api/registration/types";
import { registrationApi } from "@/api/registration";
import { InvoicePaymentProviderType } from "@/api/acquiring/types";
import { acquiringApi } from "@/api/acquiring";
export var SubscriptionStep;
(function (SubscriptionStep) {
    SubscriptionStep[SubscriptionStep["ChoosePlan"] = 0] = "ChoosePlan";
    SubscriptionStep[SubscriptionStep["Checkout"] = 1] = "Checkout";
    SubscriptionStep[SubscriptionStep["AddFunds"] = 2] = "AddFunds";
})(SubscriptionStep || (SubscriptionStep = {}));
var SubscriptionStore = /** @class */ (function () {
    function SubscriptionStore() {
        this.loading = false;
        this.available = [];
        this.my = [];
        this.selectedSubscription = null;
        this.step = 0;
        this.invoiceId = "";
        this.referralCode = "FRIEND–FOR–VAULT";
        this.promoCode = "";
        this.promoCodeApplied = false;
        this.isPromoCodeValid = false;
        this.selectedCurrency = null;
        this.selectedBlockchain = null;
        this.enoughFunds = false;
        this.convertedAmount = "";
        this.paymentSuccess = false;
        makeAutoObservable(this, {}, { autoBind: true });
    }
    Object.defineProperty(SubscriptionStore.prototype, "hasActiveSubscription", {
        get: function () {
            return this.my.some(function (subscription) {
                return subscription.status === RecurrentInvoiceStatus.ACTIVE;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "hasInitSubscription", {
        get: function () {
            return this.my.some(function (subscription) {
                return subscription.status === RecurrentInvoiceStatus.INIT;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SubscriptionStore.prototype, "hasHighestSubscription", {
        get: function () {
            return this.my.some(function (subscription) { var _a; return ((_a = subscription.subscriptionDetails) === null || _a === void 0 ? void 0 : _a.name) === "PLATINUM"; });
        },
        enumerable: false,
        configurable: true
    });
    SubscriptionStore.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.sync()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.checkInit()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionStore.prototype.sync = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, available, my, availableSubscriptionsResponse, mySubscriptionsResponse, e_1;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this, available = _a.available, my = _a.my;
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 4, , 5]);
                        return [4 /*yield*/, registrationApi.subscription.getSubscriptionsInfo()];
                    case 2:
                        availableSubscriptionsResponse = _b.sent();
                        return [4 /*yield*/, registrationApi.subscription.getSubscriptions()];
                    case 3:
                        mySubscriptionsResponse = _b.sent();
                        available = availableSubscriptionsResponse.data;
                        my = mySubscriptionsResponse.data;
                        return [3 /*break*/, 5];
                    case 4:
                        e_1 = _b.sent();
                        console.error(e_1);
                        return [3 /*break*/, 5];
                    case 5:
                        runInAction(function () {
                            _this.loading = false;
                            _this.available = available;
                            _this.my = my;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionStore.prototype.getById = function (subscriptionId) {
        return __awaiter(this, void 0, void 0, function () {
            var subscriptionByIdResponse;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, registrationApi.subscription.getSubscriptionById(subscriptionId)];
                    case 1:
                        subscriptionByIdResponse = _a.sent();
                        return [2 /*return*/, subscriptionByIdResponse.data];
                }
            });
        });
    };
    SubscriptionStore.prototype.checkInit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, invoiceId, step, selectedSubscription, init, initWithInvoice, initInvoice, subscriptionDetailId_1, currentSubscription, e_2;
            var _this = this;
            var _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this, invoiceId = _a.invoiceId, step = _a.step, selectedSubscription = _a.selectedSubscription;
                        runInAction(function () {
                            _this.loading = true;
                        });
                        init = this.my.find(function (subscription) { return subscription.status === "INIT"; });
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        if (!(init && init.id)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getById(init.id)];
                    case 2:
                        initWithInvoice = (_d.sent());
                        initInvoice = initWithInvoice.linkedInvoices.find(function (invoice) { return invoice.status === "INIT"; });
                        subscriptionDetailId_1 = (_c = (_b = this.my.find(function (sub) { return sub.id === init.id; })) === null || _b === void 0 ? void 0 : _b.subscriptionDetails) === null || _c === void 0 ? void 0 : _c.id;
                        currentSubscription = this.available.find(function (sub) { return sub.id === subscriptionDetailId_1; });
                        if (initInvoice && initInvoice.id && (currentSubscription === null || currentSubscription === void 0 ? void 0 : currentSubscription.id)) {
                            step = SubscriptionStep.Checkout;
                            invoiceId = initInvoice.id;
                            selectedSubscription = currentSubscription;
                        }
                        _d.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_2 = _d.sent();
                        console.error(e_2);
                        return [3 /*break*/, 5];
                    case 5:
                        runInAction(function () {
                            _this.step = step;
                            _this.selectedSubscription = selectedSubscription;
                            _this.invoiceId = invoiceId;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionStore.prototype.startPlan = function (accountId) {
        return __awaiter(this, void 0, void 0, function () {
            var invoiceId, startSubscriptionResponse, id, e_3;
            var _this = this;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        invoiceId = this.invoiceId;
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 5, , 6]);
                        if (!((_a = this.selectedSubscription) === null || _a === void 0 ? void 0 : _a.id)) return [3 /*break*/, 3];
                        return [4 /*yield*/, registrationApi.subscription.startSubscription({
                                subscriptionDetailsId: this.selectedSubscription.id,
                                accountId: accountId,
                            })];
                    case 2:
                        startSubscriptionResponse = _b.sent();
                        id = startSubscriptionResponse.data.id;
                        invoiceId = id || "";
                        return [3 /*break*/, 4];
                    case 3:
                        console.error("No selected subscription");
                        _b.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_3 = _b.sent();
                        console.error(e_3);
                        return [3 /*break*/, 6];
                    case 6:
                        runInAction(function () {
                            _this.invoiceId = invoiceId;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    SubscriptionStore.prototype.selectPlan = function (subscription) {
        var _this = this;
        runInAction(function () {
            _this.selectedSubscription = subscription;
            _this.step = SubscriptionStep.Checkout;
        });
    };
    SubscriptionStore.prototype.prevStep = function () {
        var _this = this;
        runInAction(function () {
            if (_this.step > SubscriptionStep.ChoosePlan) {
                _this.step -= 1;
            }
        });
    };
    SubscriptionStore.prototype.pay = function (accountId) {
        return __awaiter(this, void 0, void 0, function () {
            var paymentSuccess, createPaymentResponse, paymentId, paymentConfirmResponse, e_4;
            var _this = this;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        paymentSuccess = this.paymentSuccess;
                        runInAction(function () {
                            _this.loading = true;
                        });
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 5, , 6]);
                        if (!(accountId &&
                            this.invoiceId &&
                            ((_a = this.selectedCurrency) === null || _a === void 0 ? void 0 : _a.shortName))) return [3 /*break*/, 4];
                        return [4 /*yield*/, acquiringApi.invoice.paymentGetOrCreate({
                                accountId: accountId,
                                invoiceId: this.invoiceId,
                                currency: (_b = this.selectedCurrency) === null || _b === void 0 ? void 0 : _b.shortName,
                                type: InvoicePaymentProviderType.PLATFORM,
                            })];
                    case 2:
                        createPaymentResponse = _d.sent();
                        paymentId = createPaymentResponse.data.id;
                        if (!paymentId) return [3 /*break*/, 4];
                        return [4 /*yield*/, acquiringApi.invoice.pay({
                                paymentId: paymentId,
                                accountId: accountId,
                                invoiceId: this.invoiceId,
                                currency: (_c = this.selectedCurrency) === null || _c === void 0 ? void 0 : _c.shortName,
                                type: InvoicePaymentProviderType.PLATFORM,
                            })];
                    case 3:
                        paymentConfirmResponse = _d.sent();
                        if (paymentConfirmResponse.data) {
                            paymentSuccess = true;
                        }
                        _d.label = 4;
                    case 4: return [3 /*break*/, 6];
                    case 5:
                        e_4 = _d.sent();
                        console.error(e_4);
                        return [3 /*break*/, 6];
                    case 6:
                        runInAction(function () {
                            _this.paymentSuccess = paymentSuccess;
                            _this.loading = false;
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    Object.defineProperty(SubscriptionStore.prototype, "availableBlockchains", {
        get: function () {
            var _a, _b;
            return (((_b = (_a = this.selectedCurrency) === null || _a === void 0 ? void 0 : _a.tokens) === null || _b === void 0 ? void 0 : _b.map(function (token) { return token.blockchain; }).filter(function (blockchain) {
                return blockchain !== undefined;
            })) || []);
        },
        enumerable: false,
        configurable: true
    });
    return SubscriptionStore;
}());
export { SubscriptionStore };
