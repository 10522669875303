import React from "react";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { formattedTokenAmount } from "@broxus/js-utils";
import { Box, Button } from "@/shared/components";
import { PageHeader } from "@/shared/components/PageHeader";
import { TokenAmountInput } from "@/shared/components/TokenAmountInput";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { SendCryptoStore } from "@/pages/SendCryptoPage/stores/SendCryptoStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { ExchangeType } from "@/routes";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { WalletAddressInput } from "@/shared/components/WalletAddressInput";
import { ConfirmPopup } from "@/features/transfer/components/ConfirmPopup";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { FailPopup } from "@/features/transfer/components/FailPopup";
export var SendCryptoForm = observer(function () {
    var _a, _b, _c, _d, _e, _f;
    var sendCrypto = useStoreContext(SendCryptoStore);
    var accountList = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var balances = useStore(BalanceListStore, currencyList);
    var _g = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _g[1];
    var account = accountList.checking;
    React.useEffect(function () {
        if (account) {
            balances.sync(account.account);
        }
    }, [account, balances]);
    var onAmountChange = function (amount) {
        runInAction(function () {
            sendCrypto.amount = amount;
        });
    };
    var onSetAll = function () {
        runInAction(function () {
            var _a, _b;
            sendCrypto.amount =
                (_b = balances.data[(_a = sendCrypto.fromCurrency) === null || _a === void 0 ? void 0 : _a.slug]) === null || _b === void 0 ? void 0 : _b.balance;
        });
    };
    var onCurrencyChange = function (currency) {
        runInAction(function () {
            sendCrypto.fromCurrency = currency;
            forceUpdate();
        });
    };
    var onWalletAddressChange = function (value) {
        runInAction(function () {
            sendCrypto.walletAddress = value;
        });
    };
    var info = [
        {
            label: "Amount",
            value: ((_a = sendCrypto.feeData.value) === null || _a === void 0 ? void 0 : _a.data.amount) && sendCrypto.fromCurrency
                ? "".concat(formattedTokenAmount(sendCrypto.feeData.value.data.amount), " ").concat((_b = sendCrypto.fromCurrency.shortName) === null || _b === void 0 ? void 0 : _b.toUpperCase())
                : "–",
        },
        {
            label: "Transaction fee",
            value: sendCrypto.fee ? formattedTokenAmount(sendCrypto.fee) : "–",
        },
        {
            label: "Processing time",
            value: "Instantly",
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
            React.createElement(PageHeader, { title: "Send USDT" }),
            React.createElement(Box, { bgColor: "general-white", borderRadius: "32px", padding: "32px", display: "flex", flexDirection: "column", gap: "8px" },
                React.createElement(TokenAmountInput, { currency: sendCrypto.fromCurrency, amount: sendCrypto.amount, balances: balances.data, 
                    // TODO: Deal with type
                    // @ts-ignore
                    onCurrencyChange: onCurrencyChange, onAmountChange: onAmountChange, type: ExchangeType.Sell, onSetAll: onSetAll }),
                React.createElement(WalletAddressInput, { value: sendCrypto.walletAddress, onChange: onWalletAddressChange })),
            React.createElement(Box, { display: "flex", justifyContent: "center" },
                React.createElement(Button, { size: "l", sx: {
                        maxWidth: "300px",
                        width: "100%",
                    }, loading: sendCrypto.feeData.loading || sendCrypto.loading, onClick: sendCrypto.submit }, "Review order"))),
        sendCrypto.confirmation && (React.createElement(ConfirmPopup, { info: info, isLoading: sendCrypto.loading, onClose: sendCrypto.hideConfirmation, onSubmit: sendCrypto.submit, amount: sendCrypto.amount, currency: (_c = sendCrypto.fromCurrency) === null || _c === void 0 ? void 0 : _c.shortName, to: sendCrypto.walletAddress })),
        sendCrypto.result && (React.createElement(SuccessPopup, { minus: true, title: "You've successfully sent the crypto", address: sendCrypto.walletAddress, amount: sendCrypto.total, createdAt: (_d = sendCrypto.result) === null || _d === void 0 ? void 0 : _d.createdAt, currency: (_e = sendCrypto.result) === null || _e === void 0 ? void 0 : _e.currency, onClose: sendCrypto.reset })),
        !!sendCrypto.submitErrors.length && (React.createElement(FailPopup, { message: (_f = sendCrypto.submitErrors.at(0)) === null || _f === void 0 ? void 0 : _f.description, onClose: sendCrypto.hideErrors, onSubmit: sendCrypto.showConfirmation }))));
});
