/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export var DocSetType;
(function (DocSetType) {
    DocSetType["PHONE_VERIFICATION"] = "PHONE_VERIFICATION";
    DocSetType["EMAIL_VERIFICATION"] = "EMAIL_VERIFICATION";
    DocSetType["QUESTIONNAIRE"] = "QUESTIONNAIRE";
    DocSetType["APPLICANT_DATA"] = "APPLICANT_DATA";
    DocSetType["IDENTITY"] = "IDENTITY";
    DocSetType["IDENTITY2"] = "IDENTITY2";
    DocSetType["IDENTITY3"] = "IDENTITY3";
    DocSetType["IDENTITY4"] = "IDENTITY4";
    DocSetType["PROOF_OF_RESIDENCE"] = "PROOF_OF_RESIDENCE";
    DocSetType["PROOFOFRESIDENCE2"] = "PROOF_OF_RESIDENCE2";
    DocSetType["SELFIE"] = "SELFIE";
    DocSetType["SELFIE2"] = "SELFIE2";
})(DocSetType || (DocSetType = {}));
export var HttpStatus;
(function (HttpStatus) {
    HttpStatus["Value100CONTINUE"] = "100 CONTINUE";
    HttpStatus["Value101SWITCHINGPROTOCOLS"] = "101 SWITCHING_PROTOCOLS";
    HttpStatus["Value102PROCESSING"] = "102 PROCESSING";
    HttpStatus["Value103EARLYHINTS"] = "103 EARLY_HINTS";
    HttpStatus["Value103CHECKPOINT"] = "103 CHECKPOINT";
    HttpStatus["Value200OK"] = "200 OK";
    HttpStatus["Value201CREATED"] = "201 CREATED";
    HttpStatus["Value202ACCEPTED"] = "202 ACCEPTED";
    HttpStatus["Value203NONAUTHORITATIVEINFORMATION"] = "203 NON_AUTHORITATIVE_INFORMATION";
    HttpStatus["Value204NOCONTENT"] = "204 NO_CONTENT";
    HttpStatus["Value205RESETCONTENT"] = "205 RESET_CONTENT";
    HttpStatus["Value206PARTIALCONTENT"] = "206 PARTIAL_CONTENT";
    HttpStatus["Value207MULTISTATUS"] = "207 MULTI_STATUS";
    HttpStatus["Value208ALREADYREPORTED"] = "208 ALREADY_REPORTED";
    HttpStatus["Value226IMUSED"] = "226 IM_USED";
    HttpStatus["Value300MULTIPLECHOICES"] = "300 MULTIPLE_CHOICES";
    HttpStatus["Value301MOVEDPERMANENTLY"] = "301 MOVED_PERMANENTLY";
    HttpStatus["Value302FOUND"] = "302 FOUND";
    HttpStatus["Value302MOVEDTEMPORARILY"] = "302 MOVED_TEMPORARILY";
    HttpStatus["Value303SEEOTHER"] = "303 SEE_OTHER";
    HttpStatus["Value304NOTMODIFIED"] = "304 NOT_MODIFIED";
    HttpStatus["Value305USEPROXY"] = "305 USE_PROXY";
    HttpStatus["Value307TEMPORARYREDIRECT"] = "307 TEMPORARY_REDIRECT";
    HttpStatus["Value308PERMANENTREDIRECT"] = "308 PERMANENT_REDIRECT";
    HttpStatus["Value400BADREQUEST"] = "400 BAD_REQUEST";
    HttpStatus["Value401UNAUTHORIZED"] = "401 UNAUTHORIZED";
    HttpStatus["Value402PAYMENTREQUIRED"] = "402 PAYMENT_REQUIRED";
    HttpStatus["Value403FORBIDDEN"] = "403 FORBIDDEN";
    HttpStatus["Value404NOTFOUND"] = "404 NOT_FOUND";
    HttpStatus["Value405METHODNOTALLOWED"] = "405 METHOD_NOT_ALLOWED";
    HttpStatus["Value406NOTACCEPTABLE"] = "406 NOT_ACCEPTABLE";
    HttpStatus["Value407PROXYAUTHENTICATIONREQUIRED"] = "407 PROXY_AUTHENTICATION_REQUIRED";
    HttpStatus["Value408REQUESTTIMEOUT"] = "408 REQUEST_TIMEOUT";
    HttpStatus["Value409CONFLICT"] = "409 CONFLICT";
    HttpStatus["Value410GONE"] = "410 GONE";
    HttpStatus["Value411LENGTHREQUIRED"] = "411 LENGTH_REQUIRED";
    HttpStatus["Value412PRECONDITIONFAILED"] = "412 PRECONDITION_FAILED";
    HttpStatus["Value413PAYLOADTOOLARGE"] = "413 PAYLOAD_TOO_LARGE";
    HttpStatus["Value413REQUESTENTITYTOOLARGE"] = "413 REQUEST_ENTITY_TOO_LARGE";
    HttpStatus["Value414URITOOLONG"] = "414 URI_TOO_LONG";
    HttpStatus["Value414REQUESTURITOOLONG"] = "414 REQUEST_URI_TOO_LONG";
    HttpStatus["Value415UNSUPPORTEDMEDIATYPE"] = "415 UNSUPPORTED_MEDIA_TYPE";
    HttpStatus["Value416REQUESTEDRANGENOTSATISFIABLE"] = "416 REQUESTED_RANGE_NOT_SATISFIABLE";
    HttpStatus["Value417EXPECTATIONFAILED"] = "417 EXPECTATION_FAILED";
    HttpStatus["Value418IAMATEAPOT"] = "418 I_AM_A_TEAPOT";
    HttpStatus["Value419INSUFFICIENTSPACEONRESOURCE"] = "419 INSUFFICIENT_SPACE_ON_RESOURCE";
    HttpStatus["Value420METHODFAILURE"] = "420 METHOD_FAILURE";
    HttpStatus["Value421DESTINATIONLOCKED"] = "421 DESTINATION_LOCKED";
    HttpStatus["Value422UNPROCESSABLEENTITY"] = "422 UNPROCESSABLE_ENTITY";
    HttpStatus["Value423LOCKED"] = "423 LOCKED";
    HttpStatus["Value424FAILEDDEPENDENCY"] = "424 FAILED_DEPENDENCY";
    HttpStatus["Value425TOOEARLY"] = "425 TOO_EARLY";
    HttpStatus["Value426UPGRADEREQUIRED"] = "426 UPGRADE_REQUIRED";
    HttpStatus["Value428PRECONDITIONREQUIRED"] = "428 PRECONDITION_REQUIRED";
    HttpStatus["Value429TOOMANYREQUESTS"] = "429 TOO_MANY_REQUESTS";
    HttpStatus["Value431REQUESTHEADERFIELDSTOOLARGE"] = "431 REQUEST_HEADER_FIELDS_TOO_LARGE";
    HttpStatus["Value451UNAVAILABLEFORLEGALREASONS"] = "451 UNAVAILABLE_FOR_LEGAL_REASONS";
    HttpStatus["Value500INTERNALSERVERERROR"] = "500 INTERNAL_SERVER_ERROR";
    HttpStatus["Value501NOTIMPLEMENTED"] = "501 NOT_IMPLEMENTED";
    HttpStatus["Value502BADGATEWAY"] = "502 BAD_GATEWAY";
    HttpStatus["Value503SERVICEUNAVAILABLE"] = "503 SERVICE_UNAVAILABLE";
    HttpStatus["Value504GATEWAYTIMEOUT"] = "504 GATEWAY_TIMEOUT";
    HttpStatus["Value505HTTPVERSIONNOTSUPPORTED"] = "505 HTTP_VERSION_NOT_SUPPORTED";
    HttpStatus["Value506VARIANTALSONEGOTIATES"] = "506 VARIANT_ALSO_NEGOTIATES";
    HttpStatus["Value507INSUFFICIENTSTORAGE"] = "507 INSUFFICIENT_STORAGE";
    HttpStatus["Value508LOOPDETECTED"] = "508 LOOP_DETECTED";
    HttpStatus["Value509BANDWIDTHLIMITEXCEEDED"] = "509 BANDWIDTH_LIMIT_EXCEEDED";
    HttpStatus["Value510NOTEXTENDED"] = "510 NOT_EXTENDED";
    HttpStatus["Value511NETWORKAUTHENTICATIONREQUIRED"] = "511 NETWORK_AUTHENTICATION_REQUIRED";
})(HttpStatus || (HttpStatus = {}));
export var IdDocSubType;
(function (IdDocSubType) {
    IdDocSubType["FRONT_SIDE"] = "FRONT_SIDE";
    IdDocSubType["BACK_SIDE"] = "BACK_SIDE";
    IdDocSubType["DIRECTORS_REGISTRY"] = "DIRECTORS_REGISTRY";
    IdDocSubType["STATE_REGISTRY"] = "STATE_REGISTRY";
    IdDocSubType["INCUMBENCY_CERT"] = "INCUMBENCY_CERT";
    IdDocSubType["PROOF_OF_ADDRESS"] = "PROOF_OF_ADDRESS";
    IdDocSubType["TRUST_AGREEMENT"] = "TRUST_AGREEMENT";
    IdDocSubType["INFORMATION_STATEMENT"] = "INFORMATION_STATEMENT";
    IdDocSubType["INCORPORATION_CERT"] = "INCORPORATION_CERT";
    IdDocSubType["INCORPORATION_ARTICLES"] = "INCORPORATION_ARTICLES";
    IdDocSubType["SHAREHOLDER_REGISTRY"] = "SHAREHOLDER_REGISTRY";
    IdDocSubType["GOOD_STANDING_CERT"] = "GOOD_STANDING_CERT";
    IdDocSubType["OTHER"] = "OTHER";
})(IdDocSubType || (IdDocSubType = {}));
export var IdDocType;
(function (IdDocType) {
    IdDocType["ID_CARD"] = "ID_CARD";
    IdDocType["PASSPORT"] = "PASSPORT";
    IdDocType["DRIVERS"] = "DRIVERS";
    IdDocType["RESIDENCE_PERMIT"] = "RESIDENCE_PERMIT";
    IdDocType["UTILITY_BILL"] = "UTILITY_BILL";
    IdDocType["SELFIE"] = "SELFIE";
    IdDocType["VIDEO_SELFIE"] = "VIDEO_SELFIE";
    IdDocType["PROFILE_IMAGE"] = "PROFILE_IMAGE";
    IdDocType["ID_DOC_PHOTO"] = "ID_DOC_PHOTO";
    IdDocType["AGREEMENT"] = "AGREEMENT";
    IdDocType["CONTRACT"] = "CONTRACT";
    IdDocType["DRIVERS_TRANSLATION"] = "DRIVERS_TRANSLATION";
    IdDocType["INVESTOR_DOC"] = "INVESTOR_DOC";
    IdDocType["VEHICLE_REGISTRATION_CERTIFICATE"] = "VEHICLE_REGISTRATION_CERTIFICATE";
    IdDocType["INCOME_SOURCE"] = "INCOME_SOURCE";
    IdDocType["PAYMENT_METHOD"] = "PAYMENT_METHOD";
    IdDocType["BANK_CARD"] = "BANK_CARD";
    IdDocType["COVID_VACCINATION_FORM"] = "COVID_VACCINATION_FORM";
    IdDocType["OTHER"] = "OTHER";
    IdDocType["COMPANY_DOC"] = "COMPANY_DOC";
    IdDocType["TRANSPARENCY_REGISTRY_EXTRACT"] = "TRANSPARENCY_REGISTRY_EXTRACT";
    IdDocType["POWER_OF_ATTORNEY"] = "POWER_OF_ATTORNEY";
})(IdDocType || (IdDocType = {}));
export var IndividualType;
(function (IndividualType) {
    IndividualType["INDIVIDUAL"] = "INDIVIDUAL";
    IndividualType["OFFICER"] = "OFFICER";
    IndividualType["BENEFICIAL_OWNER"] = "BENEFICIAL_OWNER";
    IndividualType["CONTROL_PERSON"] = "CONTROL_PERSON";
    IndividualType["AUTHORIZED_PERSON"] = "AUTHORIZED_PERSON";
})(IndividualType || (IndividualType = {}));
/** Status of current invoice different for sub types */
export var InvoiceStatus;
(function (InvoiceStatus) {
    InvoiceStatus["INIT"] = "INIT";
    InvoiceStatus["PENDING"] = "PENDING";
    InvoiceStatus["PROCESSING"] = "PROCESSING";
    InvoiceStatus["COMPLETED"] = "COMPLETED";
    InvoiceStatus["CANCELED"] = "CANCELED";
    InvoiceStatus["FAILED"] = "FAILED";
    InvoiceStatus["REJECTED"] = "REJECTED";
})(InvoiceStatus || (InvoiceStatus = {}));
export var LimitType;
(function (LimitType) {
    LimitType["FEE_LIMIT"] = "FEE_LIMIT";
    LimitType["WITHDRAW_LIMIT"] = "WITHDRAW_LIMIT";
    LimitType["DEPOSIT_LIMIT"] = "DEPOSIT_LIMIT";
})(LimitType || (LimitType = {}));
/** payment status */
export var PaymentStatus;
(function (PaymentStatus) {
    PaymentStatus["INIT"] = "INIT";
    PaymentStatus["PROCESSING"] = "PROCESSING";
    PaymentStatus["COMPLETED"] = "COMPLETED";
    PaymentStatus["COMPLETED_WITHOUT_FEE"] = "COMPLETED_WITHOUT_FEE";
    PaymentStatus["FAILED"] = "FAILED";
})(PaymentStatus || (PaymentStatus = {}));
/** Interval of current recurrent invoice */
export var RecurrentInvoiceInterval;
(function (RecurrentInvoiceInterval) {
    RecurrentInvoiceInterval["DAYS30"] = "DAYS30";
    RecurrentInvoiceInterval["DAYS365"] = "DAYS365";
})(RecurrentInvoiceInterval || (RecurrentInvoiceInterval = {}));
/** Current recurrent invoice status */
export var RecurrentInvoiceStatus;
(function (RecurrentInvoiceStatus) {
    RecurrentInvoiceStatus["INIT"] = "INIT";
    RecurrentInvoiceStatus["TRIAL"] = "TRIAL";
    RecurrentInvoiceStatus["ACTIVE"] = "ACTIVE";
    RecurrentInvoiceStatus["PAYMENT_FAILED"] = "PAYMENT_FAILED";
    RecurrentInvoiceStatus["PAYMENT_REJECTED"] = "PAYMENT_REJECTED";
    RecurrentInvoiceStatus["CANCELLED"] = "CANCELLED";
    RecurrentInvoiceStatus["DEACTIVATING"] = "DEACTIVATING";
})(RecurrentInvoiceStatus || (RecurrentInvoiceStatus = {}));
export var TwoFactorType;
(function (TwoFactorType) {
    TwoFactorType["Otp"] = "otp";
    TwoFactorType["U2F"] = "u2f";
    TwoFactorType["Oob"] = "oob";
})(TwoFactorType || (TwoFactorType = {}));
export var UsResidencyStatus;
(function (UsResidencyStatus) {
    UsResidencyStatus["US_CITIZEN"] = "US_CITIZEN";
    UsResidencyStatus["RESIDENT_ALIEN"] = "RESIDENT_ALIEN";
    UsResidencyStatus["NON_RESIDENT_ALIEN"] = "NON_RESIDENT_ALIEN";
})(UsResidencyStatus || (UsResidencyStatus = {}));
/**
 * User type
 * @default "CUSTOMER"
 */
export var UserType;
(function (UserType) {
    UserType["CUSTOMER"] = "CUSTOMER";
    UserType["BUSINESS"] = "BUSINESS";
    UserType["APPLICATION"] = "APPLICATION";
})(UserType || (UserType = {}));
export var VerificationStatus;
(function (VerificationStatus) {
    VerificationStatus["INIT"] = "INIT";
    VerificationStatus["PENDING"] = "PENDING";
    VerificationStatus["GREEN"] = "GREEN";
    VerificationStatus["REJECT"] = "REJECT";
    VerificationStatus["RETRY"] = "RETRY";
    VerificationStatus["UNDEFINED"] = "UNDEFINED";
    VerificationStatus["PENDINGL2F"] = "PENDING_L2F";
})(VerificationStatus || (VerificationStatus = {}));
/**
 * Verification type and provider, e.g. KYC, KYB, etc.
 * @example "SUMSUB"
 */
export var VerificationType;
(function (VerificationType) {
    VerificationType["SUMSUB"] = "SUMSUB";
    VerificationType["PAYSPOT_OFFLINE"] = "PAYSPOT_OFFLINE";
    VerificationType["OFFLINE"] = "OFFLINE";
    VerificationType["KYB_MANUAL"] = "KYB_MANUAL";
    VerificationType["KYBLAYER2"] = "KYB_LAYER2";
    VerificationType["KYCLAYER2"] = "KYC_LAYER2";
    VerificationType["KYB_SUMSUB"] = "KYB_SUMSUB";
})(VerificationType || (VerificationType = {}));
import axios from "axios";
export var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType || (ContentType = {}));
var HttpClient = /** @class */ (function () {
    function HttpClient(_a) {
        if (_a === void 0) { _a = {}; }
        var _this = this;
        var securityWorker = _a.securityWorker, secure = _a.secure, format = _a.format, axiosConfig = __rest(_a, ["securityWorker", "secure", "format"]);
        this.securityData = null;
        this.setSecurityData = function (data) {
            _this.securityData = data;
        };
        this.request = function (_a) { return __awaiter(_this, void 0, Promise, function () {
            var secureParams, _b, requestParams, responseFormat;
            var secure = _a.secure, path = _a.path, type = _a.type, query = _a.query, format = _a.format, body = _a.body, params = __rest(_a, ["secure", "path", "type", "query", "format", "body"]);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _b = (typeof secure === "boolean" ? secure : this.secure) &&
                            this.securityWorker;
                        if (!_b) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.securityWorker(this.securityData)];
                    case 1:
                        _b = (_c.sent());
                        _c.label = 2;
                    case 2:
                        secureParams = (_b) ||
                            {};
                        requestParams = this.mergeRequestParams(params, secureParams);
                        responseFormat = format || this.format || undefined;
                        if (type === ContentType.FormData &&
                            body &&
                            body !== null &&
                            typeof body === "object") {
                            body = this.createFormData(body);
                        }
                        if (type === ContentType.Text &&
                            body &&
                            body !== null &&
                            typeof body !== "string") {
                            body = JSON.stringify(body);
                        }
                        return [2 /*return*/, this.instance.request(__assign(__assign({}, requestParams), { headers: __assign(__assign({}, (requestParams.headers || {})), (type ? { "Content-Type": type } : {})), params: query, responseType: responseFormat, data: body, url: path }))];
                }
            });
        }); };
        this.instance = axios.create(__assign(__assign({}, axiosConfig), { baseURL: axiosConfig.baseURL || "https://gw.api-stg.evercash.eu/reg" }));
        this.secure = secure;
        this.format = format;
        this.securityWorker = securityWorker;
    }
    HttpClient.prototype.mergeRequestParams = function (params1, params2) {
        var method = params1.method || (params2 && params2.method);
        return __assign(__assign(__assign(__assign({}, this.instance.defaults), params1), (params2 || {})), { headers: __assign(__assign(__assign({}, ((method &&
                this.instance.defaults.headers[method.toLowerCase()]) ||
                {})), (params1.headers || {})), ((params2 && params2.headers) || {})) });
    };
    HttpClient.prototype.stringifyFormItem = function (formItem) {
        if (typeof formItem === "object" && formItem !== null) {
            return JSON.stringify(formItem);
        }
        else {
            return "".concat(formItem);
        }
    };
    HttpClient.prototype.createFormData = function (input) {
        var _this = this;
        if (input instanceof FormData) {
            return input;
        }
        return Object.keys(input || {}).reduce(function (formData, key) {
            var property = input[key];
            var propertyContent = property instanceof Array ? property : [property];
            for (var _i = 0, propertyContent_1 = propertyContent; _i < propertyContent_1.length; _i++) {
                var formItem = propertyContent_1[_i];
                var isFileType = formItem instanceof Blob || formItem instanceof File;
                formData.append(key, isFileType ? formItem : _this.stringifyFormItem(formItem));
            }
            return formData;
        }, new FormData());
    };
    return HttpClient;
}());
export { HttpClient };
/**
 * @title Registration
 * @version 0.2.0-1b98a0ac
 * @baseUrl https://gw.api-stg.evercash.eu/reg
 *
 * Registration and authorisation service for other services
 */
var RegistrationApi = /** @class */ (function (_super) {
    __extends(RegistrationApi, _super);
    function RegistrationApi() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.auth = {
            /**
             * @description One time password  requested by 'sendOneTimePassword', DEPRECTAED: Client must use MFA instead of legacy OTP.
             *
             * @tags Authentication
             * @name SendOneTimePassword
             * @summary Send one time password
             * @request GET:/auth/sendOneTimePassword
             * @deprecated
             */
            sendOneTimePassword: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/auth/sendOneTimePassword", method: "GET", query: query, format: "json" }, params));
            },
            /**
             * @description OAuth2.0 endpoint, supported grant types: `client_credentials`, `refresh_token`, `password`
             *
             * @tags Authentication
             * @name GetToken
             * @summary OAuth2.0 endpoint
             * @request POST:/auth/token
             */
            getToken: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/auth/token", method: "POST", body: data, type: ContentType.Json, format: "json" }, params));
            },
        };
        _this.discounts = {
            /**
             * No description
             *
             * @tags Discounts
             * @name GetDiscounts
             * @request GET:/discounts
             */
            getDiscounts: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/discounts", method: "GET" }, params));
            },
            /**
             * No description
             *
             * @tags Discounts
             * @name RedeemDiscount
             * @request POST:/discounts/redeem
             */
            redeemDiscount: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/discounts/redeem", method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags Discounts
             * @name RevokeDiscount
             * @request POST:/discounts/revoke
             */
            revokeDiscount: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/discounts/revoke", method: "POST", body: data, type: ContentType.Json }, params));
            },
        };
        _this.layer2 = {
            /**
             * No description
             *
             * @tags layer-2-webhook-controller
             * @name Webhook1
             * @request POST:/layer2/webhook
             */
            webhook1: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/layer2/webhook", method: "POST", body: data, type: ContentType.Json }, params));
            },
        };
        _this.mfa = {
            /**
             * @description Create new Two Factor authenticator, return private key and challenge to activate.
             *
             * @tags Two Factor
             * @name CreateTwoFactorAuthentication
             * @summary Create Two Factor authenticator
             * @request POST:/mfa/associate
             */
            createTwoFactorAuthentication: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/mfa/associate", method: "POST", body: data, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Return list of Two Factor authentications
             *
             * @tags Two Factor
             * @name GetListOfTwoFactorAuthentications
             * @summary List of Two Factor authentications
             * @request GET:/mfa/authenticators
             */
            getListOfTwoFactorAuthentications: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/mfa/authenticators", method: "GET", query: query, format: "json" }, params));
            },
            /**
             * @description Create challenge for selected Two Factor authenticator,
             *
             * @tags Two Factor
             * @name CreateChallenge
             * @summary Create challenge for Two Factor authenticator
             * @request POST:/mfa/challenge
             */
            createChallenge: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/mfa/challenge", method: "POST", body: data, type: ContentType.Json, format: "json" }, params));
            },
        };
        _this.subscription = {
            /**
             * @description Returns all subscriptions
             *
             * @tags Subscriptions
             * @name GetSubscriptions
             * @summary Get all subscriptions
             * @request GET:/subscription
             */
            getSubscriptions: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/subscription", method: "GET" }, params));
            },
            /**
             * @description Start subscription for user, returns invoice response for payment
             *
             * @tags Subscriptions
             * @name StartSubscription
             * @summary Start subscription
             * @request POST:/subscription
             */
            startSubscription: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/subscription", method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * @description Get available subscriptions for user
             *
             * @tags Subscriptions
             * @name GetSubscriptionsInfo
             * @summary Get available subscriptions
             * @request GET:/subscription/details/available
             */
            getSubscriptionsInfo: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/subscription/details/available", method: "GET" }, params));
            },
            /**
             * No description
             *
             * @tags Subscriptions
             * @name Webhook
             * @request POST:/subscription/webhook
             * @secure
             */
            webhook: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/subscription/webhook", method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Cancel subscription
             *
             * @tags Subscriptions
             * @name CancelSubscription
             * @summary Cancel subscription
             * @request DELETE:/subscription/{subscriptionId}
             */
            cancelSubscription: function (subscriptionId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/subscription/".concat(subscriptionId), method: "DELETE" }, params));
            },
            /**
             * @description Returns subscription by id
             *
             * @tags Subscriptions
             * @name GetSubscriptionById
             * @summary Get subscription by id
             * @request GET:/subscription/{subscriptionId}
             */
            getSubscriptionById: function (subscriptionId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/subscription/".concat(subscriptionId), method: "GET" }, params));
            },
            /**
             * @description Update subscription payment account
             *
             * @tags Subscriptions
             * @name UpdateSubscriptionPaymentAccount
             * @summary Update subscription payment account
             * @request PUT:/subscription/{subscriptionId}/payment-account
             */
            updateSubscriptionPaymentAccount: function (subscriptionId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/subscription/".concat(subscriptionId, "/payment-account"), method: "PUT", query: query }, params));
            },
            /**
             * No description
             *
             * @tags Subscriptions
             * @name SubscriptionRenew
             * @request PUT:/subscription/{subscriptionId}/renew
             * @deprecated
             */
            subscriptionRenew: function (subscriptionId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/subscription/".concat(subscriptionId, "/renew"), method: "PUT" }, params));
            },
        };
        _this.user = {
            /**
             * @description Creates new user, by default user is not approved.If confirm code not provided, user would not be created. OTP code will be send.
             *
             * @tags User
             * @name CreateUser
             * @summary Create new user
             * @request POST:/user
             */
            createUser: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user", method: "POST", body: data, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Address objects if success
             *
             * @tags User Physical Address
             * @name ListAddress
             * @summary List all address
             * @request GET:/user/address
             * @secure
             */
            listAddress: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/address", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Address objects if success
             *
             * @tags User Physical Address
             * @name CreateAddress
             * @summary Create an address
             * @request POST:/user/address
             * @secure
             */
            createAddress: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/address", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Deletes user address, couldn't delete residential address. Returns 200
             *
             * @tags User Physical Address
             * @name DeleteUserAddress
             * @summary Delete address
             * @request DELETE:/user/address/{uuid}
             * @secure
             */
            deleteUserAddress: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/address/".concat(uuid), method: "DELETE", secure: true }, params));
            },
            /**
             * @description Returns 200 with User Address if success
             *
             * @tags User Physical Address
             * @name UpdateAddress
             * @summary Update address
             * @request PUT:/user/address/{uuid}
             * @secure
             */
            updateAddress: function (uuid, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/address/".concat(uuid), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Email objects if success
             *
             * @tags User Physical Address
             * @name UpdateAddressSetPrimaryResidential
             * @summary Set address as primary residential address
             * @request POST:/user/address/{uuid}/residential
             * @secure
             */
            updateAddressSetPrimaryResidential: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/address/".concat(uuid, "/residential"), method: "POST", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Email objects if success
             *
             * @tags User Emails
             * @name ListEmail
             * @summary List all emails
             * @request GET:/user/email
             * @secure
             */
            listEmail: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/email", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Email objects if success
             *
             * @tags User Emails
             * @name CreateEmail
             * @summary Create email
             * @request POST:/user/email
             * @secure
             */
            createEmail: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/email", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description If no code provided, new confirmation code will be sent and 202 will be returned. If code provided, code will be checked and primary email will be updated and 200 will be returned. Old email will be marked deleted
             *
             * @tags User Emails
             * @name UpdatePrimaryEmail
             * @summary Update primary email
             * @request PUT:/user/email
             * @secure
             */
            updatePrimaryEmail: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/email", method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Deletes email, couldn't delete primary email. Returns 200.
             *
             * @tags User Emails
             * @name DeleteEmail
             * @summary Delete an email
             * @request DELETE:/user/email/{uuid}
             * @secure
             */
            deleteEmail: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/email/".concat(uuid), method: "DELETE", secure: true }, params));
            },
            /**
             * @description Returns 200 with User Email objects if success
             *
             * @tags User Emails
             * @name CreateEmailRequestConfirm
             * @summary Create confirmation code for email
             * @request POST:/user/email/{uuid}
             * @secure
             */
            createEmailRequestConfirm: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/email/".concat(uuid), method: "POST", secure: true, format: "json" }, params));
            },
            /**
             * @description Confirm email with code. If email was the only one confirmed, it becomes primary.Returns 200 with User Email objects if success
             *
             * @tags User Emails
             * @name CreateEmailConfirm
             * @summary Confirm email with code
             * @request POST:/user/email/{uuid}/confirm
             * @secure
             */
            createEmailConfirm: function (uuid, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/email/".concat(uuid, "/confirm"), method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Email objects if success
             *
             * @tags User Emails
             * @name UpdateEmailSetPrimary
             * @summary Set email primary
             * @request POST:/user/email/{uuid}/primary
             * @secure
             */
            updateEmailSetPrimary: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/email/".concat(uuid, "/primary"), method: "POST", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Info objects if success
             *
             * @tags User
             * @name GetUserGroups
             * @summary List all user groups
             * @request GET:/user/groups
             * @secure
             */
            getUserGroups: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/groups", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Info objects if success
             *
             * @tags User
             * @name GetUserInfo
             * @summary Retrieve user info
             * @request GET:/user/info
             * @secure
             */
            getUserInfo: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/info", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Info objects if success
             *
             * @tags User
             * @name UpdateUserInfo
             * @summary Update user info with PII
             * @request PATCH:/user/info
             * @secure
             */
            updateUserInfo: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/info", method: "PATCH", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Returns 200 with Company Info objects if success, 204 if no content
             *
             * @tags User Business
             * @name GetCompanyInfo
             * @summary Retrieve company info
             * @request GET:/user/info/company
             * @secure
             */
            getCompanyInfo: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/info/company", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with Company Info objects if success
             *
             * @tags User Business
             * @name UpdateCompanyInfo
             * @summary Update company info
             * @request PUT:/user/info/company
             * @secure
             */
            updateCompanyInfo: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/info/company", method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Returns 200 with Company Info Individual objects if success
             *
             * @tags User Business
             * @name CreateCompanyInfoIndividual
             * @summary Create individual for company info
             * @request POST:/user/info/company/individual
             * @secure
             */
            createCompanyInfoIndividual: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/info/company/individual", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Returns 200 with Company Info Individual Info objects if success
             *
             * @tags User Business
             * @name DeleteCompanyInfoIndividual
             * @summary Delete individual info
             * @request DELETE:/user/info/company/individual/{uuid}
             * @secure
             */
            deleteCompanyInfoIndividual: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/info/company/individual/".concat(uuid), method: "DELETE", secure: true }, params));
            },
            /**
             * @description Returns 200 with Company Info Individual Info objects if success
             *
             * @tags User Business
             * @name UpdateCompanyInfoIndividual
             * @summary Update individual info
             * @request PUT:/user/info/company/individual/{uuid}
             * @secure
             */
            updateCompanyInfoIndividual: function (uuid, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/info/company/individual/".concat(uuid), method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Returns 200 with Company Info Individuals objects if success, 204 if no content
             *
             * @tags User Business
             * @name GetCompanyInfoIndividuals
             * @summary Retrieve company info individuals
             * @request GET:/user/info/company/individuals
             * @secure
             */
            getCompanyInfoIndividuals: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/info/company/individuals", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Returns 200 with User Limits objects if success
             *
             * @tags User
             * @name GetLimits
             * @summary Get user limits
             * @request GET:/user/limits
             * @secure
             */
            getLimits: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/limits", method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description If no code provided, new confirmation code will be sent and 202 will be returned. If code provided, code will be checked and phone will be created and 200 will be returned
             *
             * @tags User Phone
             * @name CreatePhone
             * @summary Create user phone
             * @request POST:/user/phone
             * @secure
             */
            createPhone: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/phone", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description If no code provided, new confirmation code will be sent and 202 will be returned. If code provided, code will be checked and phone will be updated and 200 will be returned
             *
             * @tags User Phone
             * @name UpdatePhone
             * @summary Update user phone
             * @request PUT:/user/phone
             * @secure
             */
            updatePhone: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/phone", method: "PUT", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Confirm phone. Returns 200 if success
             *
             * @tags User Phone
             * @name ConfirmPhone
             * @summary Confirm phone
             * @request POST:/user/phone/confirm
             * @secure
             */
            confirmPhone: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/phone/confirm", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Request phone confirmation code. Returns 200 if success
             *
             * @tags User Phone
             * @name RequestConfirmPhone
             * @summary Request phone code
             * @request POST:/user/phone/requestConfirm
             * @secure
             */
            requestConfirmPhone: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/phone/requestConfirm", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * No description
             *
             * @tags User Notifications
             * @name RegisterDevice
             * @summary Register device for notifications
             * @request POST:/user/registerDevice
             * @secure
             */
            registerDevice: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/registerDevice", method: "POST", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Set new password with signed code for password reset. Returns 200 with User Info objects if success.
             *
             * @tags User Password
             * @name ResetPassword
             * @summary Reset Password, phase 2
             * @request POST:/user/resetPassword
             */
            resetPassword: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/resetPassword", method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * @description Requests signed code for password reset with TFA code. If no code provided in request, new would be send to username (email or password).Response 200 is signed payload for reset password. Response 202 if code is sent. Response the same if user not found.
             *
             * @tags User Password
             * @name ResetPasswordConfirm
             * @summary Reset Password, phase 1
             * @request POST:/user/resetPasswordRequest
             */
            resetPasswordConfirm: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/resetPasswordRequest", method: "POST", body: data, type: ContentType.Json }, params));
            },
            /**
             * No description
             *
             * @tags User Sessions
             * @name GetUserActiveSessions
             * @summary Get All Active Sessions
             * @request GET:/user/session
             * @secure
             */
            getUserActiveSessions: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/session", method: "GET", secure: true }, params));
            },
            /**
             * @description Logout from list of sessions or all sessions (if list is empty)
             *
             * @tags User Sessions
             * @name LogoutUser
             * @summary Logout from sessions
             * @request PUT:/user/session/logout
             * @secure
             */
            logoutUser: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/session/logout", method: "PUT", body: data, secure: true, type: ContentType.Json }, params));
            },
            /**
             * @description Logout from current session (by token)
             *
             * @tags User Sessions
             * @name LogoutCurrentSession
             * @summary Logout
             * @request DELETE:/user/session/logout/current
             * @secure
             */
            logoutCurrentSession: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/session/logout/current", method: "DELETE", secure: true }, params));
            },
            /**
             * @description Returns 200 with Terms and Conditions object if success
             *
             * @tags User Terms and Conditions
             * @name GetLatestTermsAndConditions
             * @summary Retrieve latest terms and conditions
             * @request GET:/user/terms-and-conditions
             * @secure
             */
            getLatestTermsAndConditions: function (params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/terms-and-conditions", method: "GET", secure: true }, params));
            },
            /**
             * @description Returns 200 with Terms and Conditions object if success
             *
             * @tags User Terms and Conditions
             * @name SignTermsAndConditions
             * @summary Sign terms and conditions
             * @request PUT:/user/terms-and-conditions
             * @secure
             */
            signTermsAndConditions: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/user/terms-and-conditions", method: "PUT", body: data, secure: true, type: ContentType.Json }, params));
            },
        };
        _this.verification = {
            /**
             * @description Retrieve a verification status
             *
             * @tags Verification
             * @name GetVerificationStatus
             * @summary Retrieve a verification status
             * @request GET:/verification
             * @secure
             */
            getVerificationStatus: function (query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/verification", method: "GET", query: query, secure: true, format: "json" }, params));
            },
            /**
             * @description Creates new user verification with provided info.Email and phone must be confirmed. Next step is complete verification in SumSub framework using provided token
             *
             * @tags Verification
             * @name CreateVerification
             * @summary Create verification
             * @request POST:/verification
             * @secure
             */
            createVerification: function (data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/verification", method: "POST", body: data, secure: true, type: ContentType.Json, format: "json" }, params));
            },
            /**
             * @description Retrieve a verification by UUID
             *
             * @tags Verification
             * @name GetVerificationByUuid
             * @summary Retrieve a verification by UUID
             * @request GET:/verification/{kycId}
             */
            getVerificationByUuid: function (kycId, query, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/verification/".concat(kycId), method: "GET", query: query, format: "json" }, params));
            },
            /**
             * @description Upload document to a verification by uuid
             *
             * @tags Verification
             * @name UploadDocument
             * @summary Upload document to a verification by uuid
             * @request POST:/verification/{kycId}/docs
             */
            uploadDocument: function (kycId, data, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/verification/".concat(kycId, "/docs"), method: "POST", body: data, type: ContentType.FormData, format: "json" }, params));
            },
            /**
             * @description Delete document by uuid
             *
             * @tags Verification
             * @name DeleteDocument
             * @summary Delete document by uuid
             * @request DELETE:/verification/{kycId}/docs/{docId}
             * @secure
             */
            deleteDocument: function (kycId, docId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/verification/".concat(kycId, "/docs/").concat(docId), method: "DELETE", secure: true, format: "json" }, params));
            },
            /**
             * @description Retrieve document by uuid
             *
             * @tags Verification
             * @name GetDocument
             * @summary Retrieve document by uuid
             * @request GET:/verification/{kycId}/docs/{docId}
             * @secure
             */
            getDocument: function (kycId, docId, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/verification/".concat(kycId, "/docs/").concat(docId), method: "GET", secure: true, format: "json" }, params));
            },
            /**
             * @description Update verification status from INIT to PENDING
             *
             * @tags Verification
             * @name UpdateVerificationStatus
             * @summary Update verification status to pending
             * @request PUT:/verification/{uuid}/status
             * @secure
             */
            updateVerificationStatus: function (uuid, params) {
                if (params === void 0) { params = {}; }
                return _this.request(__assign({ path: "/verification/".concat(uuid, "/status"), method: "PUT", secure: true, format: "json" }, params));
            },
        };
        return _this;
    }
    return RegistrationApi;
}(HttpClient));
export { RegistrationApi };
