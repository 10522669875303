var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { formattedTokenAmount } from "@broxus/js-utils";
import BigNumber from "bignumber.js";
import debounce from "lodash.debounce";
import { ExchangeType, ROUTES } from "@/routes";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { TokenAmountInput } from "@/shared/components/TokenAmountInput";
import { ExchangeStore } from "@/pages/ExchangePage/stores/ExchangeStore";
import { generateQueryPath } from "@/shared/utils/path";
import { ExchangeModal } from "@/pages/ExchangePage/components/Modal";
import { useCreateDirectExchange } from "@/api/wallet";
import { useToggler } from "@/shared/hooks/useToggler";
import { CardListStore } from "@/shared/stores/CardListStore";
export var Exchange = observer(function (_a) {
    var _b, _c, _d, _e, _f;
    var type = _a.type;
    var navigate = useNavigate();
    var cryptoOrderReviewModal = useToggler();
    var search = useSearchParams()[0];
    var from = search.get("from");
    var to = search.get("to");
    var fromCardId = search.get("fromCardId");
    var toCardId = search.get("toCardId");
    var cardList = useStoreContext(CardListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var accounts = useStoreContext(AccountListStore);
    var balances = useStore(BalanceListStore, currencyList);
    var exchange = useStoreContext(ExchangeStore);
    var account = accounts.checking;
    var _g = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _g[1];
    React.useEffect(function () {
        balances.sync.apply(balances, accounts.list.map(function (item) { return item.account; }));
        forceUpdate();
    }, [balances, accounts.list, from, to]);
    React.useEffect(function () {
        if (fromCardId) {
            var fromCard_1 = cardList.list.find(function (card) { return card.id === fromCardId; });
            runInAction(function () {
                exchange.fromCard = fromCard_1;
                forceUpdate();
            });
        }
        else {
            runInAction(function () {
                exchange.fromCard = undefined;
            });
        }
        if (toCardId) {
            var toCard_1 = cardList.list.find(function (card) { return card.id === toCardId; });
            runInAction(function () {
                exchange.toCard = toCard_1;
                forceUpdate();
            });
        }
        else {
            runInAction(function () {
                exchange.toCard = undefined;
            });
        }
        if (from) {
            var fromCurrency_1 = currencyList.getBySlug(from);
            runInAction(function () {
                exchange.fromCurrency = fromCurrency_1;
            });
        }
        if (to) {
            var toCurrency_1 = currencyList.getBySlug(to);
            runInAction(function () {
                exchange.toCurrency = toCurrency_1;
            });
        }
        else {
            var toCurrency_2 = currencyList.getBySlug("usdt");
            runInAction(function () {
                exchange.toCurrency = toCurrency_2;
            });
        }
    }, [
        currencyList.list,
        cardList.list,
        cardList,
        exchange,
        from,
        to,
        fromCardId,
        toCardId,
        exchange,
    ]);
    var _h = useCreateDirectExchange({}), mutateAsync = _h.mutateAsync, data = _h.data, isPendingPreExchange = _h.isPending, reset = _h.reset;
    var preExchangeInfo = data === null || data === void 0 ? void 0 : data.data;
    var preExchange = React.useCallback(function (value, direction) { return __awaiter(void 0, void 0, void 0, function () {
        var amount, data_1, fee;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    amount = "";
                    if (!(account &&
                        ((_a = exchange.fromCurrency) === null || _a === void 0 ? void 0 : _a.slug) &&
                        ((_b = exchange.toCurrency) === null || _b === void 0 ? void 0 : _b.slug))) return [3 /*break*/, 3];
                    return [4 /*yield*/, mutateAsync({
                            dryRun: true,
                            data: {
                                fromAccount: account.account,
                                toAccount: exchange.toCard
                                    ? exchange.toCard.accountId
                                    : exchange.fromCard
                                        ? exchange.fromCard.accountId
                                        : account.account,
                                fromCurrency: type === ExchangeType.Buy
                                    ? exchange.toCurrency.slug
                                    : exchange.fromCurrency.slug,
                                toCurrency: type === ExchangeType.Buy
                                    ? exchange.fromCurrency.slug
                                    : exchange.toCurrency.slug,
                                fromAmount: value,
                            },
                        })];
                case 1:
                    data_1 = _c.sent();
                    amount = data_1.data.toAmount;
                    if (!(data_1.data.feeAmount && data_1.data.feeCurrency)) return [3 /*break*/, 3];
                    return [4 /*yield*/, currencyList.convert(data_1.data.fromCurrency, data_1.data.toCurrency, data_1.data.feeAmount)];
                case 2:
                    fee = _c.sent();
                    amount = new BigNumber(amount).minus(fee !== null && fee !== void 0 ? fee : "0").toFixed();
                    _c.label = 3;
                case 3:
                    runInAction(function () {
                        if (direction === "from") {
                            exchange.toAmount = amount;
                        }
                        else {
                            exchange.fromAmount = amount;
                        }
                    });
                    return [2 /*return*/];
            }
        });
    }); }, [account, exchange, mutateAsync, currencyList, type]);
    var debounceExchange = React.useCallback(debounce(preExchange, 400), [
        preExchange,
    ]);
    var fromDecimals = (_b = currencyList.bySlug[preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromCurrency.toLowerCase()]) === null || _b === void 0 ? void 0 : _b.decimals;
    var rate = (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount) && (preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
        ? BigNumber(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.fromAmount)
            .div(preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.toAmount)
            .toFixed(fromDecimals || 6)
        : undefined;
    var onSuccess = function () {
        reset();
        exchange.reset();
        balances.sync(account.account);
    };
    var onFromAmountChange = function (value) {
        debounceExchange(value, "from");
        runInAction(function () {
            exchange.fromAmount = value;
        });
    };
    var onToAmountChange = function (value) {
        debounceExchange(value, "to");
        runInAction(function () {
            exchange.toAmount = value;
        });
    };
    var onFromChange = function (c, cardId) {
        var _a, _b;
        navigate(generateQueryPath(ROUTES.exchange, {
            type: type,
        }, {
            from: c,
            to: (_a = exchange.toCurrency) === null || _a === void 0 ? void 0 : _a.slug,
            toCardId: (_b = exchange.toCard) === null || _b === void 0 ? void 0 : _b.id,
            fromCardId: cardId,
        }));
    };
    var onToChange = function (c, cardId) {
        var _a, _b;
        navigate(generateQueryPath(ROUTES.exchange, {
            type: type,
        }, __assign({ from: (_a = exchange.fromCurrency) === null || _a === void 0 ? void 0 : _a.slug, to: c, fromCardId: (_b = exchange.fromCard) === null || _b === void 0 ? void 0 : _b.id }, (cardId && { toCardId: cardId }))));
    };
    var onSetFromAll = function () {
        if (exchange.fromCurrency && exchange.fromCurrency.slug) {
            var balanceData = balances.data[exchange.fromCurrency.slug];
            if (balanceData) {
                var formattedBalance_1 = formattedTokenAmount(balanceData.balance);
                debounceExchange(formattedBalance_1, "from");
                runInAction(function () {
                    exchange.fromAmount = formattedBalance_1;
                });
            }
        }
    };
    var onSetToAll = function () {
        if (exchange.toCurrency && exchange.toCurrency.slug) {
            var balanceData = balances.data[exchange.toCurrency.slug];
            if (balanceData) {
                var formattedBalance_2 = formattedTokenAmount(balanceData.balance);
                debounceExchange(formattedBalance_2, "to");
                runInAction(function () {
                    exchange.toAmount = formattedBalance_2;
                });
            }
        }
    };
    var toggleExchangeType = function () {
        var _a, _b;
        navigate(generateQueryPath(ROUTES.exchange, {
            type: type === ExchangeType.Buy
                ? ExchangeType.Sell
                : ExchangeType.Buy,
        }, {
            from: (_a = exchange.fromCurrency) === null || _a === void 0 ? void 0 : _a.slug,
            to: (_b = exchange.toCurrency) === null || _b === void 0 ? void 0 : _b.slug,
        }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
                React.createElement(BaseIcon, { icon: "Back", size: 24, onClick: function () {
                        navigate(generateQueryPath(ROUTES.walletList));
                    } }),
                React.createElement(Typography, { type: "text28" }, exchange.fromCard
                    ? "Add Money"
                    : type === ExchangeType.Buy
                        ? "Buy ".concat((_c = exchange.fromCurrency) === null || _c === void 0 ? void 0 : _c.shortName)
                        : "Sell ".concat((_d = exchange.fromCurrency) === null || _d === void 0 ? void 0 : _d.shortName))),
            React.createElement(Box, null,
                exchange.fromCurrency &&
                    exchange.toCurrency &&
                    balances.data && (React.createElement(Box, { padding: "32px", borderRadius: "32px", bgColor: "general-white", marginTop: "32px", display: "flex", flexDirection: "column", gap: "8px", position: "relative", boxSizing: "borderBox" },
                    React.createElement(TokenAmountInput, { amount: exchange.fromAmount, currency: exchange.fromCurrency, card: exchange.fromCard, balances: balances.data, onCurrencyChange: onFromChange, onAmountChange: onFromAmountChange, type: type === ExchangeType.Buy
                            ? ExchangeType.Buy
                            : ExchangeType.Sell, onSetAll: onSetFromAll }),
                    React.createElement(Box, null,
                        React.createElement(BaseIcon, { position: "absolute", zIndex: 2, top: "calc(50% - 16px)", left: "calc(50% - 16px)", icon: "Arrow", size: 44, rotate: type === ExchangeType.Buy ? 0 : 180, onClick: toggleExchangeType })),
                    React.createElement(TokenAmountInput, { amount: exchange.toAmount, currency: exchange.toCurrency, card: exchange.toCard, balances: balances.data, onCurrencyChange: onToChange, onAmountChange: onToAmountChange, type: type === ExchangeType.Buy
                            ? ExchangeType.Sell
                            : ExchangeType.Buy, onSetAll: onSetToAll }))),
                React.createElement(Box, { display: "flex", justifyContent: "center", width: "100%", marginTop: "32px", onClick: cryptoOrderReviewModal.on },
                    React.createElement(Button, { size: "l", width: "300px" }, "Review order")))),
        account &&
            ((_e = exchange.fromCurrency) === null || _e === void 0 ? void 0 : _e.slug) &&
            ((_f = exchange.toCurrency) === null || _f === void 0 ? void 0 : _f.slug) && (React.createElement(ExchangeModal, { onSuccess: onSuccess, isOpen: cryptoOrderReviewModal.enabled, onClose: cryptoOrderReviewModal.off, data: {
                fromAccount: account.account,
                toAccount: exchange.toCard
                    ? exchange.toCard.accountId
                    : exchange.fromCard
                        ? exchange.fromCard.accountId
                        : account.account,
                fromCurrency: exchange.fromCurrency.slug,
                toCurrency: exchange.toCurrency.slug,
                fromAmount: exchange.fromAmount,
                id: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.id,
                feeAmount: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeAmount,
                feeCurrency: preExchangeInfo === null || preExchangeInfo === void 0 ? void 0 : preExchangeInfo.feeCurrency,
            }, rateInfo: {
                label: "".concat(from, " rate"),
                value: rate ? "".concat(rate, " ").concat(from) : "–",
            }, toAmount: exchange.toAmount }))));
});
