import React from "react";
import { observer } from "mobx-react-lite";
import { action, runInAction } from "mobx";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStep, SubscriptionStore, } from "@/shared/stores/SubscriptionStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { PromoCodeInput } from "@/shared/components/PromoCodeInput";
import { Input } from "@/shared/components/Input";
import { onChange } from "@/shared/utils/input";
import { PaymentCurrencyDropdown } from "@/shared/components/PaymentCurrencyDropdown";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { SuccessPaymentPopup } from "@/pages/SubscriptionPage/components/SuccessPaymentPopup";
export var Checkout = observer(function () {
    var _a, _b, _c;
    var subscriptions = useStoreContext(SubscriptionStore);
    var accountList = useStoreContext(AccountListStore);
    var account = accountList.checking;
    var selectedSubscription = subscriptions.selectedSubscription;
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var accounts = useStoreContext(AccountListStore);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accounts.list.map(function (acc) { return acc.account; }));
    }, [balanceList, accounts.list]);
    var currencies = currencyList.withWalletEnabled.filter(function (c) { return c.type === "CRYPTO"; });
    var onCurrencyChange = function (c, enoughFunds, convertedAmount) {
        runInAction(function () {
            subscriptions.selectedCurrency = c;
            subscriptions.enoughFunds = enoughFunds;
            subscriptions.convertedAmount = convertedAmount;
        });
    };
    React.useEffect(function () {
        if (account) {
            subscriptions.startPlan(account.account);
        }
    }, [account, subscriptions]);
    var applyPromoCode = action(function () {
        subscriptions.promoCodeApplied = true;
    });
    var clearPromoCode = action(function () {
        subscriptions.promoCodeApplied = false;
        subscriptions.promoCode = "";
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center", marginTop: "48px" },
                React.createElement(BaseIcon, { icon: "Back", size: 24, style: {
                        cursor: "pointer",
                    }, onClick: subscriptions.prevStep }),
                React.createElement(Typography, { textAlign: "center", type: "text28Bold" },
                    "Get ", selectedSubscription === null || selectedSubscription === void 0 ? void 0 :
                    selectedSubscription.name)),
            React.createElement(Box, { padding: "32px", bgColor: "general-white", borderRadius: "32px", marginTop: "32px" },
                React.createElement(PaymentCurrencyDropdown, { totalAmount: (selectedSubscription === null || selectedSubscription === void 0 ? void 0 : selectedSubscription.amount.toString()) || "0", selectedCurrency: subscriptions.selectedCurrency || currencies[0], balances: balanceList.data, currencies: currencies, onCurrencyChange: onCurrencyChange }),
                !subscriptions.enoughFunds && (React.createElement(Box, { display: "flex", gap: "12px", sx: {
                        backgroundColor: "#F6F7F8",
                    }, borderRadius: "16px", alignItems: "center", padding: "16px", marginTop: "44px", boxSizing: "border-box" },
                    React.createElement(BaseIcon, { icon: "Warning", size: 44 }),
                    React.createElement(Typography, { color: "black-500", type: "text14" },
                        "You need to add",
                        " ", (_a = subscriptions.selectedCurrency) === null || _a === void 0 ? void 0 :
                        _a.shortName,
                        " ",
                        subscriptions.convertedAmount,
                        " to your",
                        " ", (_b = subscriptions.selectedCurrency) === null || _b === void 0 ? void 0 :
                        _b.description,
                        " ",
                        "wallet to get subscribed")))),
            React.createElement(Box, { padding: "32px", bgColor: "general-white", borderRadius: "32px", marginTop: "32px" },
                subscriptions.referralCode && (React.createElement(Input, { value: subscriptions.referralCode, disabled: true, label: "Referral code", InputProps: {
                        endAdornment: React.createElement(BaseIcon, { icon: "Success" }),
                    } })),
                React.createElement(Box, { marginTop: "24px" },
                    React.createElement(PromoCodeInput, { value: subscriptions.promoCode, onChange: onChange(action(function (val) {
                            if (!subscriptions.promoCodeApplied) {
                                subscriptions.promoCode = val;
                            }
                        })), applied: subscriptions.promoCodeApplied, onApply: function () {
                            applyPromoCode();
                        }, onClear: function () {
                            clearPromoCode();
                        }, isValid: subscriptions.isPromoCodeValid })),
                React.createElement(Box, { bgColor: "black-50", padding: "24px", borderRadius: "16px", marginTop: "16px" },
                    React.createElement(Box, { display: "flex", justifyContent: "space-between" },
                        React.createElement(Typography, { color: "black-500", type: "text16" }, "Total"),
                        React.createElement(Typography, { type: "text16" },
                            "$", selectedSubscription === null || selectedSubscription === void 0 ? void 0 :
                            selectedSubscription.amount)))),
            React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "32px" }, subscriptions.enoughFunds ? (React.createElement(Button, { size: "l", loading: subscriptions.loading, onClick: function () {
                    subscriptions.pay(account.account);
                } },
                "Get Premium for $", selectedSubscription === null || selectedSubscription === void 0 ? void 0 :
                selectedSubscription.amount,
                "/m")) : (React.createElement(Button, { size: "l", onClick: function () {
                    runInAction(function () {
                        subscriptions.step =
                            SubscriptionStep.AddFunds;
                    });
                } },
                "Add ", (_c = subscriptions.selectedCurrency) === null || _c === void 0 ? void 0 :
                _c.shortName,
                " and Get Premium")))),
        subscriptions.paymentSuccess && React.createElement(SuccessPaymentPopup, null)));
});
