import { makeAutoObservable, runInAction } from "mobx";
var ExchangeStore = /** @class */ (function () {
    function ExchangeStore() {
        this.fromAmount = "0";
        this.toAmount = "0";
        makeAutoObservable(this, {}, { autoBind: true });
    }
    ExchangeStore.prototype.reset = function () {
        var _this = this;
        runInAction(function () {
            _this.fromAmount = "0";
            _this.toAmount = "0";
        });
    };
    return ExchangeStore;
}());
export { ExchangeStore };
