import React from "react";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
var features = [
    "Fee-free ATM withdrawals",
    "Customer support",
    "Currency exchange amount with no additional fees",
    "1% cashback",
    "Save on international transfer fees",
];
export var SubscriptionCard = function (_a) {
    var subscription = _a.subscription, onSelect = _a.onSelect;
    return (React.createElement(Box, { padding: "36px 12px", borderRadius: "16px", bgColor: "general-white" },
        React.createElement(Box, null,
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "4px" },
                React.createElement(Typography, { sx: {
                        fontSize: "18px",
                        fontWeight: "bold",
                    }, color: "black-1200" }, subscription.name.toUpperCase()),
                React.createElement(Typography, { sx: {
                        fontSize: "18px",
                        fontWeight: "bold",
                    }, color: "deep-blue-500" },
                    "$",
                    subscription.amount),
                React.createElement(Typography, { color: "black-1200", type: "text14" }, "monthly")),
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center" },
                React.createElement(Typography, { color: "general-green" }, "First month FREE")),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "4px", marginTop: "16px" }, features.map(function (feature) { return (React.createElement(Box, { display: "flex", gap: "4px", alignItems: "center" },
                React.createElement(BaseIcon, { icon: "Point" }),
                React.createElement(Typography, { key: feature }, feature))); }))),
        React.createElement(Box, { marginTop: "52px", display: "flex", flexDirection: "column" },
            React.createElement(Button, { size: "l", onClick: function () { return onSelect(subscription); } },
                "Get ",
                subscription.name.toUpperCase()))));
};
