import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { SigninPageStore } from "@/pages/SigninPage/stores/SigninPageStore";
import { ROUTES } from "@/routes";
import { Button, FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Link } from "@/shared/components/Link";
import { ResendCodeBtn } from "@/shared/components/ResendCodeBtn";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
import { MfaStore } from "@/shared/stores/MfaStore";
export var VerificationForm = observer(function () {
    var _a;
    var page = useStoreContext(SigninPageStore);
    var mfa = useStoreContext(MfaStore);
    React.useEffect(function () {
        mfa.createChallenge();
    }, [mfa]);
    return (React.createElement(AuthForm, { onBack: page.reset, onSubmit: page.submitCode, title: "2-Step Authentication", desc: React.createElement(React.Fragment, null, "Enter the code from authenticator app"), content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { disabled: true, type: "text", label: "Email", value: page.email }),
            React.createElement(FormControlInput, { type: "text", label: "Verification code", value: page.code, onChange: onChange(action(function (value) {
                    page.code = value;
                })), disabled: page.loading, variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, InputProps: {
                    endAdornment: (React.createElement(ResendCodeBtn, { loading: page.loading, onSubmit: page.submit, count: page.timer.count })),
                } }),
            React.createElement(Link, { alignSelf: "center", color: "black-500", to: generatePath(ROUTES.signin) }, "Hasn't arrived?")), footer: React.createElement(Button, { type: "submit", width: "100%", disabled: page.loading || !page.code }, "Continue") }));
});
