import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@/shared/components";
import { PageHeader } from "@/shared/components/PageHeader";
import { ChooseCryptoList } from "@/shared/components/ChooseCryptoList";
import { generateQueryPath } from "@/shared/utils/path";
import { ExchangeType, ROUTES } from "@/routes";
export var ChooseCryptoExchange = function () {
    var navigate = useNavigate();
    var onCurrencySelect = function (currency) {
        navigate(generateQueryPath(ROUTES.exchange, {
            type: ExchangeType.Buy,
        }, {
            from: currency.slug,
        }));
    };
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
        React.createElement(PageHeader, { title: "Choose Crypto" }),
        React.createElement(ChooseCryptoList, { onCurrencySelect: onCurrencySelect })));
};
