import React from "react";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { useSearchParams } from "react-router-dom";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { ReceiveCryptoStep, ReceiveCryptoStore, } from "@/pages/ReceiveCryptoPage/stores/ReceiveCryptoStore";
import { ChooseCryptoReceive } from "@/pages/ReceiveCryptoPage/components/ChooseCryptoReceive";
import { GetAddress } from "@/pages/ReceiveCryptoPage/components/GetAddress";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var ReceiveCryptoPage = observer(function () {
    var _a, _b;
    var searchParams = useSearchParams()[0];
    var currencyList = useStoreContext(CurrencyListStore);
    var receiveCrypto = useStore(ReceiveCryptoStore);
    var ReceiveCryptoProvider = useProvider(ReceiveCryptoStore);
    var blockchainQueryParam = (_a = searchParams.get("blockchain")) !== null && _a !== void 0 ? _a : "";
    var currencySlugQueryParam = (_b = searchParams.get("currency")) !== null && _b !== void 0 ? _b : "";
    React.useEffect(function () {
        var _a, _b;
        if (blockchainQueryParam && currencySlugQueryParam) {
            var currency_1 = currencyList.getBySlug(currencySlugQueryParam);
            if (currency_1) {
                var blockchain_1 = (_b = (_a = currency_1.tokens) === null || _a === void 0 ? void 0 : _a.find(function (token) { var _a; return ((_a = token.blockchain) === null || _a === void 0 ? void 0 : _a.slug) === blockchainQueryParam; })) === null || _b === void 0 ? void 0 : _b.blockchain;
                if (blockchain_1) {
                    runInAction(function () {
                        receiveCrypto.currency = currency_1;
                        receiveCrypto.blockchain = blockchain_1;
                        receiveCrypto.step = ReceiveCryptoStep.GetAddress;
                    });
                }
            }
        }
    }, [currencyList.list, blockchainQueryParam, currencySlugQueryParam]);
    return (React.createElement(ReceiveCryptoProvider, { value: receiveCrypto }, receiveCrypto.step === ReceiveCryptoStep.ChooseCrypto ? (React.createElement(ChooseCryptoReceive, null)) : (React.createElement(GetAddress, null))));
});
