import * as React from "react";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { useBack } from "@/shared/hooks/useBack";
export var PageHeader = function (_a) {
    var title = _a.title, onBack = _a.onBack;
    var back = useBack();
    return (React.createElement(Box, { display: "flex", alignItems: "center", gap: "12px" },
        React.createElement(BaseIcon, { icon: "Back", onClick: onBack !== null && onBack !== void 0 ? onBack : back, size: 24 }),
        React.createElement(Box, { display: "flex", flexDirection: "column" },
            React.createElement(Typography, { type: "text24" }, title))));
};
