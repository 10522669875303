import React from "react";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import worldwide from "@/shared/img/worldwide.png";
import { generateQueryPath } from "@/shared/utils/path";
import { ROUTES } from "@/routes";
import { Link } from "react-router-dom";
export var UpgradeToProBanner = function (_a) {
    var onClose = _a.onClose;
    return (React.createElement(Box, { width: "100%", height: "170px", borderRadius: "22px", padding: "16px", boxSizing: "border-box", sx: {
            position: "relative",
            backgroundImage: "url(".concat(worldwide, ")"),
            backgroundPosition: "right bottom",
            backgroundSize: "130px",
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
            padding: "20px",
            borderRadius: "16px",
            backgroundColor: "#393D49",
        } },
        React.createElement(BaseIcon, { icon: "CloseLight", position: "absolute", top: "8px", right: "8px", style: {
                cursor: "pointer",
            }, onClick: onClose }),
        React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "flex-start", height: "100%", justifyContent: "space-between" },
            React.createElement(Box, null,
                React.createElement(Box, null,
                    React.createElement(Typography, { color: "general-white", sx: {
                            fontSize: "18px",
                            fontWeight: "bold",
                        } }, "BECOME A FINTECH PRO")),
                React.createElement(Box, { marginTop: "8px" },
                    React.createElement(Typography, { color: "general-white", type: "text16", sx: { fontWeight: 400 } },
                        "Transfer instantly with just their card number.",
                        React.createElement("br", null),
                        "Follow for more just in one click"))),
            React.createElement(Button, { sx: {
                    backgroundColor: "rgba(255, 255, 255, 0.1)",
                }, LinkComponent: Link, to: generateQueryPath(ROUTES.subscription) }, "Upgrade to Pro"))));
};
