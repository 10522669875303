import * as React from "react";
import { generatePath, Link } from "react-router-dom";
import { Box, Button } from "@/shared/components";
import { ExchangeType, ROUTES } from "@/routes";
import { generateQueryPath } from "@/shared/utils/path";
export var AssetsActionPanel = function () {
    return (React.createElement(Box, { display: "flex", gap: "10px" },
        React.createElement(Button, { size: "s", width: "120px", LinkComponent: Link, to: generateQueryPath(ROUTES.exchange, {
                type: ExchangeType.Crypto,
            }) }, "Trade"),
        React.createElement(Button, { size: "s", width: "120px", variant: "LightBlue", LinkComponent: Link, to: generatePath(ROUTES.sendCrypto) }, "Send"),
        React.createElement(Button, { variant: "LightBlue", size: "s", width: "120px", LinkComponent: Link, to: generatePath(ROUTES.receiveCrypto) }, "Receive")));
};
