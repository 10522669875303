import { observer } from "mobx-react-lite";
import React from "react";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { Box, Typography } from "@/shared/components";
import { VerificationBanner } from "@/entities/Verification";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStep, SubscriptionStore, } from "@/shared/stores/SubscriptionStore";
import { ChoosePlan } from "@/pages/SubscriptionPage/components/ChoosePlan";
import { Checkout } from "@/pages/SubscriptionPage/components/Checkout";
import { AddFunds } from "@/shared/components/AddFunds";
export var SubscriptionPage = observer(function () {
    var data = useGetVerificationInfo().data;
    var status = data === null || data === void 0 ? void 0 : data.status;
    var subscriptions = useStoreContext(SubscriptionStore);
    var renderStep = function () {
        switch (subscriptions.step) {
            case SubscriptionStep.ChoosePlan:
                return React.createElement(ChoosePlan, null);
            case SubscriptionStep.Checkout:
                return React.createElement(Checkout, null);
            case SubscriptionStep.AddFunds:
                if (!subscriptions.selectedCurrency ||
                    !subscriptions.selectedBlockchain) {
                    return (React.createElement(Box, null,
                        React.createElement(Typography, { type: "text16" }, "Currency or blockchain data is missing. Please try again later.")));
                }
                return (React.createElement(AddFunds, { selectedCurrency: subscriptions.selectedCurrency, selectedBlockchain: subscriptions.selectedBlockchain }));
            default:
                return null;
        }
    };
    return (React.createElement(React.Fragment, null, status !== VerificationStatus.GREEN ? (React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr", gap: "20px" },
        React.createElement(VerificationBanner, null))) : (renderStep())));
});
