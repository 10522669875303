import React from "react";
import { observer } from "mobx-react-lite";
import { BaseIcon, Box, Typography } from "@/shared/components";
import { SubscriptionCard } from "@/pages/SubscriptionPage/components/Card";
import { useStoreContext } from "@/shared/hooks/useStore";
import { SubscriptionStore } from "@/shared/stores/SubscriptionStore";
export var ChoosePlan = observer(function () {
    var subscriptions = useStoreContext(SubscriptionStore);
    var onSelect = function (subscription) {
        subscriptions.selectPlan(subscription);
    };
    return (React.createElement(Box, null,
        React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center", marginTop: "48px" },
            React.createElement(BaseIcon, { icon: "Back", size: 24, style: {
                    cursor: "pointer",
                }, onClick: function () { } }),
            React.createElement(Typography, { textAlign: "center", type: "text28Bold" }, "Choose your perfect plan")),
        React.createElement(Box, { display: "grid", gridTemplateColumns: "repeat(3,260px)", marginTop: "72px", gap: "40px" }, subscriptions.available.map(function (subscription) { return (React.createElement(SubscriptionCard, { subscription: subscription, onSelect: onSelect })); }))));
});
