import { ThemeProvider } from "@mui/system";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { Root } from "@/shared/components/Root";
import { theme } from "@/shared/constants/theme";
import "@/shared/styles/index.scss";
var root = createRoot(document.getElementById("root"));
var queryClient = new QueryClient();
root.render(
// <React.StrictMode>
React.createElement(QueryClientProvider, { client: queryClient },
    React.createElement(BrowserRouter, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(Root, null),
            React.createElement(ToastContainer, { position: "top-center", autoClose: 3000, draggable: false, closeOnClick: false, hideProgressBar: true, icon: false, closeButton: false, transition: Slide })))));
