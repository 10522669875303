import React from "react";
import { observer } from "mobx-react-lite";
import { Box, Button, FormControlInput } from "@/shared/components";
import { FormRow } from "@/shared/components/FormRow";
import { countries } from "@/shared/constants/countries";
import { FormControlSelect } from "@/shared/components/FormControlSelect";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardOrderStore } from "@/pages/CardOrderPage/stores/CardOrderStore";
export var Billing = observer(function () {
    var cardOrder = useStoreContext(CardOrderStore);
    var countryOptions = countries.map(function (item) { return ({
        key: item.codeAlpha3,
        label: "".concat(item.flag, " ").concat(item.name),
    }); });
    var handleNextStepClick = function () {
        cardOrder.nextStep();
    };
    var handleInputChange = function (field, value) {
        cardOrder.setBillingAddress(field, value !== null && value !== void 0 ? value : "");
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { padding: "32px", borderRadius: "22px", bgColor: "general-white" },
            React.createElement(Box, null,
                React.createElement(FormRow, null,
                    React.createElement(FormControlInput, { value: cardOrder.firstName, label: "First Name", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                            cardOrder.firstName = e.target.value;
                        } }),
                    React.createElement(FormControlInput, { value: cardOrder.lastName, label: "Last Name", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                            cardOrder.lastName = e.target.value;
                        } })),
                React.createElement(FormRow, { marginTop: "30px" },
                    React.createElement(FormControlSelect, { value: cardOrder.billingAddress.country, options: countryOptions, label: "Registered Country", errorPosition: "relative", onChange: function (_, option) {
                            return handleInputChange("country", option === null || option === void 0 ? void 0 : option.key);
                        } }),
                    React.createElement(FormControlInput, { value: cardOrder.billingAddress.city, label: "City", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                            return handleInputChange("city", e.target.value);
                        } })),
                React.createElement(FormRow, { marginTop: "30px" },
                    React.createElement(FormControlInput, { value: cardOrder.billingAddress.state, label: "State", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                            return handleInputChange("state", e.target.value);
                        } }),
                    React.createElement(FormControlInput, { value: cardOrder.billingAddress.postalCode, label: "Postal/ZIP code", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                            return handleInputChange("postalCode", e.target.value);
                        } })),
                React.createElement(FormRow, { marginTop: "30px" },
                    React.createElement(FormControlInput, { value: cardOrder.billingAddress.addressLine1, label: "Address line 1", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                            return handleInputChange("addressLine1", e.target.value);
                        } }),
                    React.createElement(FormControlInput, { value: cardOrder.billingAddress.addressLine2, label: "Address line 2", errorPosition: "relative", contentEditable: false, onChange: function (e) {
                            return handleInputChange("addressLine2", e.target.value);
                        } })))),
        React.createElement(Box, { display: "flex", justifyContent: "center" },
            React.createElement(Button, { onClick: handleNextStepClick, size: "l", style: {
                    maxWidth: "328px",
                    width: "100%",
                } }, "Next"))));
});
