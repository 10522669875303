import React from "react";
import { observer } from "mobx-react-lite";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CardListStore } from "@/shared/stores/CardListStore";
import { Box, Button, Typography } from "@/shared/components";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { VerificationBanner } from "@/entities/Verification";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { ROUTES } from "@/routes";
import { Cards } from "@/pages/HomePage/components/Cards";
export var CardPage = observer(function () {
    var navigate = useNavigate();
    var data = useGetVerificationInfo().data;
    var status = data === null || data === void 0 ? void 0 : data.status;
    var cardList = useStoreContext(CardListStore);
    React.useEffect(function () {
        cardList.sync();
    }, [cardList]);
    return (React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { type: "text28Bold" }, "Cards"),
            status === VerificationStatus.GREEN && (React.createElement(Button, { icon: "PlusSmall", LinkComponent: Link, to: generatePath(ROUTES.orderCard) }, "Add card"))),
        status !== VerificationStatus.GREEN && (React.createElement(Box, { display: "grid", gridTemplateColumns: "1fr", gap: "20px" },
            React.createElement(VerificationBanner, null))),
        React.createElement(Box, { padding: "32px", borderRadius: "22px", bgColor: "general-white" },
            React.createElement(Cards, null))));
});
